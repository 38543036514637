import programService from '../service/programService';

export const FETCH_PROGRAM_SUCCESS = 'FETCH_PROGRAM_SUCCESS';

const programActionsSuccess = (type, episode, step, title) => ({
  type: type,
  payload: episode,
  title: title,
  step: step,
});

export const programActions = () => {
  return async dispatch => {
    let res = await programService.getProgram();
    let programTitleList = [];

    res?.value.sort((a, b) => {
      return Number(a?.createdAt) < Number(b?.createdAt) ? 1 : -1;
    });

    res?.value.map(p => {
      if (p?.isShow) {
        programTitleList.push(p?.title);
      }
    });

    dispatch(programActionsSuccess(FETCH_PROGRAM_SUCCESS, res?.value ? res?.value : [], 1, programTitleList));
  };
};
