import axiosInstance from './AxiosInstance';
import axios from 'axios';
import { API_URL, GOOGLE_MAP_API, GOOGLE_MAP_KEY } from './index';
import moment from 'moment';
import _ from 'lodash';

// Get data..
export const getData = async item => {
  if (!item) return;

  let res = await axiosInstance.get(`${API_URL}/${item}`);

  // console.log(`${item} :`, res?.data?.value);
  return res?.data;
};

// Get dataDetail
export const getDataListDetail = async (item, id) => {
  let res = await axiosInstance.get(`${API_URL}/${item}/${id}`);

  // console.log(`${item} :`, res?.data?.value);
  return res.data;
};

// Get dataDetail
export const getDataDetail = async (item, id, detailId) => {
  let res = await axiosInstance.get(`${API_URL}/${item}/${id}/${detailId}`);

  // console.log(`${item} :`, res?.data?.value);
  return res.data;
};

// Set data..
export const setData = async (item, param) => {
  let res = await axiosInstance.post(`${API_URL}/${item}/`, param);

  return res;
};

// Update data..
export const UpdateData = async (item, param) => {
  let res = await axiosInstance.put(`${API_URL}/${item}/`, param);

  return res;
};

// Delete data [Body]
export const deleteData = async (item, id) => {
  let res = await axiosInstance.delete(`${API_URL}/${item}/${id}`);

  return res;
};

// Delete data [Param]
export const deleteParamData = async (item, param) => {
  let res = await axiosInstance.delete(`${API_URL}/${item}?${param}`);

  return res;
};

// Delete data [Form]
export const deleteFormData = async (item, form) => {
  let res = await axiosInstance.delete(`${API_URL}/${item}`, form);

  return res.data;
};

// Find Id, Password / Change Fwd ---------------------------
export const findId = async param => {
  let res = await axios.get(`${API_URL}/user/findId?name=${param.name}&hp=${param.hp}`);

  return res.data;
};

export const findPassword = async form => {
  let res = await axios.put(`${API_URL}/user/findPassword`, form);

  return res;
};

export const ChangePassword = async form => {
  let res = await axiosInstance.put(`${API_URL}/user/changePassword`, form);

  return res.data;
};
//  -----------------------------------------

// Id Validate Check
export const idCheck = async id => {
  let res = await axios.get(`${API_URL}/user/check/${id}`);

  return res.data;
};

// Get VodSignedUrl
export const getVodSignedUrl = async key => {
  let res = await axiosInstance.get(`${API_URL}/episode/vodSignedUrl?key=${key}`);

  // console.log('### GetVodKey : ', res.data.url);
  return res.data;
};

// Get Today ScheduleData
export const getTodayOnairData = async date => {
  let today = moment().format('YYYYMMDD');

  if (date) {
    today = date;
  }

  let response = await axiosInstance.get(`${API_URL}/schedule/byDate?date=${today}`);

  return response?.data?.value;
};

//  Today Onair Format
export const todayOnairFormat = onair => {
  let onAirTimeValidate = [];
  let nowTime = moment();
  let ch_n = Number(nowTime.format('YYYYMMDDHHmm'));

  let res = onair.sort((a, b) => {
    let __a = Number(`${a?.time.split(':')[0]}${a?.time.split(':')[1]}`);
    let __b = Number(`${b?.time.split(':')[0]}${b?.time.split(':')[1]}`);
    return __a > __b ? 1 : -1;
  });

  if (res.length > 0) {
    onAirTimeValidate = _.cloneDeep(res);
    let liveCheck = 0;

    onAirTimeValidate.map((item, idx) => {
      item['end'] = res[idx + 1]?.time ? res[idx + 1]?.time : '00:00';
      item['endFull'] = res[idx + 1]?.full ? res[idx + 1]?.full : new Date();
    });

    onAirTimeValidate.map(item => {
      let start = moment(item?.full);
      let end = moment(item?.endFull);
      let ch_s = Number(start.format('YYYYMMDDHHmm'));
      let ch_e = Number(end.format('YYYYMMDDHHmm'));

      if (ch_s <= ch_n && ch_e > ch_n) {
        let diffTime = (end - start) / 1000;
        let endDiffTime = (nowTime - start) / 1000;
        let gauge = Math.floor((endDiffTime / diffTime) * 100);

        item['live'] = true;
        item['gauge'] = gauge;
        liveCheck++;
      } else {
        item['live'] = false;
        item['gauge'] = 0;
      }
    });

    // 당일 날짜만 체크.
    let _start = Number(moment(onAirTimeValidate[0]?.id).format('YYYYMMDD'));
    let _nowDay = Number(moment(nowTime).format('YYYYMMDD'));
    if (liveCheck < 1 && _nowDay === _start) {
      let timeOverOnair = {
        time: '00:00',
        end: onAirTimeValidate[0].time,
        ststus: 'vod',
        program: {
          title: '방송 준비중',
        },
        live: true,
        gauge: 0,
      };

      onAirTimeValidate.push(timeOverOnair);
    }
  }

  // console.log('### TODAY ONAIR DATA ### : ', onAirTimeValidate);
  return onAirTimeValidate;
};

export const getCoordinates = addr => {
  return new Promise((resolve, reject) => {
    try {
      axios.get(`${GOOGLE_MAP_API}/geocode/json?address=${encodeURI(addr)}&key=${GOOGLE_MAP_KEY}`).then(response => {
        if (response.data.status === 'OK') {
          resolve({
            lat: response.data.results[0].geometry.location.lat,
            lng: response.data.results[0].geometry.location.lng,
          });
        }
      });
    } catch (e) {
      reject(e);
    }
  });
};

export const checkImg = async url => {
  try {
    await axios.get(url);
  } catch (error) {
    return error?.response?.status;
  }
};
