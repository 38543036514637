import { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';

const ScrollToTop = props => {
  const [prevProps, setPrevProps] = useState(props);

  useEffect(() => {
    setPrevProps(props);
  }, [props]);

  useEffect(() => {
    if (prevProps.location.pathname !== props.location.pathname) {
      window.scrollTo(0, 0);
    }
  });

  return props.children;
};

export default withRouter(ScrollToTop);
