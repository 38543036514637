import React, { useState, useLayoutEffect, useContext } from 'react';

import MobileDrawer from './MobileDrawer';
import { Menu, Logo } from '../styles/layOutStyles';

import { RIGHTMENU, LEFTMENU } from './Menu';

import { setData } from '../constants/api_index';

import { APP_PREFIX, VALIDATECODE, USR_ROLE, STORE_URL } from '../constants/index';
import { Grid } from '@material-ui/core';
import { getCookie } from '../helpers/cookies';

import Alert from '../helpers/Alert';

import HeaderSearchBar from './HeaderSearchBar';
import HeaderMobileSearchBar from './HeaderMobileSearchBar';

import { LayoutContext } from '../context/layoutContext';
import { useHistory } from 'react-router-dom';

import { removeCookie } from '../helpers/cookies';
import { Auth } from 'aws-amplify';
import { connect } from 'react-redux';

export const logOut = userInfo => {
  if (userInfo) {
    setData('loginLog', {
      userId: userInfo?.id,
      type: 'logout',
    });
  }

  removeCookie(`${APP_PREFIX}_jwt`);
  removeCookie(`${APP_PREFIX}_user`);
  removeCookie(`${APP_PREFIX}_divice`);
  removeCookie(`${APP_PREFIX}_usr_ck`);

  localStorage.clear();
  sessionStorage.clear();

  Auth.signOut().then(() => {
    window.location.reload();
  });
};

const Topbar = props => {
  const history = useHistory();
  const { matchese } = useContext(LayoutContext);

  const { search, headerTitle, logoShow, userInfo } = props;
  const [userName, setUserName] = useState('...');

  useLayoutEffect(() => {
    if (userInfo?.id) {
      if (!userInfo[VALIDATECODE]) {
        setUserName('미인증');
      } else {
        setUserName(userInfo?.name);
      }
    }
  }, [userInfo]);

  const goSite = path => {
    if (path === 'store') {
      window.open(STORE_URL, '_blank');
    } else {
      history.push(process.env.PUBLIC_URL + `${path}`);
    }
  };

  const adminSite = () => {
    const admCode = getCookie(`${APP_PREFIX}_usr_ck`);

    if (admCode) {
      window.open(`https://admin.eftv.co.kr/pages/auth/login?auth=${userInfo?.email}&authcode=${encodeURI(admCode)}`, '_blank');
    } else {
      Alert('', '다시 로그인 후 이용가능힙니다.');
      return;
    }
  };

  return (
    <React.Fragment>
      {matchese ? (
        <>
          <MobileDrawer
            headerTitle={headerTitle}
            search={search}
            logoShow={logoShow}
            userInfo={userInfo}
            adminSite={adminSite}
            logOut={() => logOut(userInfo)}
          />
          {search && <HeaderMobileSearchBar />}
        </>
      ) : (
        <Grid container item xs={12}>
          <Grid item xs={5}>
            <Grid container justifyContent='flex-start' alignItems='center'>
              <Logo className='headerLogo' onClick={() => goSite('/home')} />
              {RIGHTMENU.map(menu => {
                return (
                  <Grid key={menu.label} onClick={() => goSite(menu.pathname)}>
                    <Menu arch={menu.anchor}>{menu.label}</Menu>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <Grid container justifyContent='flex-end' alignItems='center' style={{ height: '100%' }}>
              {userInfo && (
                <Grid onClick={() => goSite('my?tab=0')}>
                  <Menu fontSize='0.8rem'>{`${userName} 님`}</Menu>
                </Grid>
              )}
              {userInfo && (
                <Grid onClick={() => logOut(userInfo)}>
                  <Menu fontSize='0.8rem'>로그아웃</Menu>
                </Grid>
              )}

              {LEFTMENU.filter(l => {
                if (userInfo) {
                  return !l.login;
                } else {
                  return l;
                }
              }).map(menu => {
                return (
                  <Grid key={menu.label} onClick={() => goSite(menu.pathname)}>
                    <Menu fontSize='0.8rem'>{menu.label}</Menu>
                  </Grid>
                );
              })}

              {userInfo && userInfo?.role !== USR_ROLE && (
                <Grid onClick={adminSite}>
                  <Menu fontSize='0.8rem'>관리자메뉴</Menu>
                </Grid>
              )}

              <HeaderSearchBar />
            </Grid>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    userInfo: state.userData.user,
  };
};

export default React.memo(connect(mapStateToProps)(Topbar));
