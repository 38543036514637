import React, { useState, useMemo } from 'react';
export const GlobalContext = React.createContext({
  currentTheme: '',
  themeSwitchHandler: () => {},
});

const GlobalContextProvider = props => {
  const [currentTheme, setCurrentTheme] = useState(
    window.localStorage.getItem('theme') == null ? 'light' : window.localStorage.getItem('theme'),
  );

  const themeSwitchHandler = themeType => {
    window.localStorage.setItem('theme', themeType);
    setCurrentTheme(themeType);
  };

  return (
    <GlobalContext.Provider
      value={{
        theme: currentTheme,
        themeSwitchHandler: themeSwitchHandler,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
