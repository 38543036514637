import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { CookiesProvider } from 'react-cookie';

import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';

import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import rootReducer from './redux/reducers/rootReducer';
import { userActions } from './redux/actions/userActions';
import { programActions } from './redux/actions/programActions';
import { bannerActions } from './redux/actions/bannerActions';

import { storageActions } from './redux/actions/storageActions';

import GlobalContextProvider from './context/globalContext';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunk));

const store = createStore(rootReducer, composedEnhancer);

const persistor = persistStore(store);

store.dispatch(userActions());
store.dispatch(bannerActions());
store.dispatch(programActions());

// storage save
store.dispatch(storageActions());

ReactDOM.render(
  <React.StrictMode>
    <CookiesProvider>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalContextProvider>
            <App />
          </GlobalContextProvider>
        </PersistGate>
      </Provider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

reportWebVitals();
