import './App.css';
import './assets/scss/style.scss';

import React, { lazy, Suspense } from 'react';
import ScrollToTop from './helpers/scroll-top';
import LayoutContextProvider from './context/layoutContext';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

const Home = lazy(() => import('./pages/Home'));

const SearchPage = lazy(() => import('./pages/search/SearchPage'));
const SearchDetailPage = lazy(() => import('./pages/search/SearchDetailPage'));

const Login = lazy(() => import('./pages/user/Login'));

const Join = lazy(() => import('./pages/user/Join'));
const AuthResult = lazy(() => import('./pages/user/AuthResult'));

const MyPage = lazy(() => import('./pages/my/MyPage'));

const OnAirPage = lazy(() => import('./pages/onair/OnAirPage'));

const VodListPage = lazy(() => import('./pages/vod/VodListPage'));
const VodDetailPage = lazy(() => import('./pages/vod/VodDetailPage'));
const VodProgramDetail = lazy(() => import('./pages/vod/VodProgramDetail'));
const VodOpinionDetail = lazy(() => import('./pages/vod/VodOpinionDetail'));
const VodOpinionWrite = lazy(() => import('./pages/vod/VodOpinionWrite'));

const VodPopup = lazy(() => import('./pages/vod/VodPopup'));
const MainUrlPopupItem = lazy(() => import('./components/MainUrlPopupItem'));

const YoutubeDetailPage = lazy(() => import('./pages/vod/YoutubeDetailPage'));

const CommunityPage = lazy(() => import('./pages/community/CommunityPage'));
const CommunityDetailPage = lazy(() => import('./pages/community/CommunityDetailPage'));
const FishCommuDetailPage = lazy(() => import('./pages/community/FishCommuDetailPage'));

const FishSubmitPage = lazy(() => import('./pages/community/FishSubmitPage'));
const FishSubmitConfirmPage = lazy(() => import('./pages/community/FishSubmitConfirmPage'));

const VoucherPage = lazy(() => import('./pages/voucher/VoucherPage'));
const PayAgreePage = lazy(() => import('./pages/voucher/PayAgreePage'));
const PayResult = lazy(() => import('./pages/voucher/PayResult'));
const PayPalTest = lazy(() => import('./pages/voucher/PayPalTest'));

const HelpPage = lazy(() => import('./pages/help/HelpPage'));
const HelpDetailPage = lazy(() => import('./pages/help/HelpBoardDetailPage'));

const NotFound = lazy(() => import('./pages/other/NotFound'));

const SchedulePage = lazy(() => import('./pages/schedule/SchedulePage'));
const ScheduleDetailPage = lazy(() => import('./pages/schedule/ScheduleDetailPage'));

const CompanyPage = lazy(() => import('./pages/company/CompanyPage'));

const BusinessProposalPage = lazy(() => import('./pages/subPages/BusinessProposalPage'));
const UserTermsPage = lazy(() => import('./pages/subPages/UserTermsPage'));
const PersonalPage = lazy(() => import('./pages/subPages/PersonalPage'));
const SiteMap = lazy(() => import('./pages/subPages/SiteMap'));

function App() {
  return (
    <LayoutContextProvider>
      <Router>
        <ScrollToTop>
          <Suspense
            fallback={
              <div className='lazy-loading-bg'>
                <span className='lazy-loading'></span>
              </div>
            }
          >
            <Switch>
              <Route exact path={process.env.PUBLIC_URL + '/'} component={Home} />
              <Route exact path={process.env.PUBLIC_URL + '/home'} component={Home} />

              <Route exact path={process.env.PUBLIC_URL + '/search'} component={SearchPage} />
              <Route exact path={process.env.PUBLIC_URL + '/search-more'} component={SearchDetailPage} />

              <Route exact path={process.env.PUBLIC_URL + '/login'} component={Login} />
              <Route exact path={process.env.PUBLIC_URL + '/join'} component={Join} />

              <Route exact path={process.env.PUBLIC_URL + '/my'} component={MyPage} />

              <Route exact path={process.env.PUBLIC_URL + '/onair'} component={OnAirPage} />

              <Route exact path={process.env.PUBLIC_URL + '/vod'} component={VodListPage} />
              <Route exact path={process.env.PUBLIC_URL + '/vod-detail'} component={VodDetailPage} />
              <Route exact path={process.env.PUBLIC_URL + '/program-detail'} component={VodProgramDetail} />
              <Route exact path={process.env.PUBLIC_URL + '/opinion-detail'} component={VodOpinionDetail} />
              <Route exact path={process.env.PUBLIC_URL + '/opinion-write'} component={VodOpinionWrite} />

              <Route exact path={process.env.PUBLIC_URL + '/vod/PopupPlayer'} component={VodPopup} />
              <Route exact path={process.env.PUBLIC_URL + '/popup/:id'} component={MainUrlPopupItem} />

              <Route exact path={process.env.PUBLIC_URL + '/youtube-detail'} component={YoutubeDetailPage} />

              <Route exact path={process.env.PUBLIC_URL + '/community'} component={CommunityPage} />
              <Route exact path={process.env.PUBLIC_URL + '/community-detail'} component={CommunityDetailPage} />
              <Route exact path={process.env.PUBLIC_URL + '/fish-detail'} component={FishCommuDetailPage} />

              <Route exact path={process.env.PUBLIC_URL + '/submit-page/:id'} component={FishSubmitPage} />
              <Route exact path={process.env.PUBLIC_URL + '/community-confirm/:id'} component={FishSubmitConfirmPage} />

              <Route exact path={process.env.PUBLIC_URL + '/voucher'} component={VoucherPage} />
              <Route exact path={process.env.PUBLIC_URL + '/checkout'} component={PayAgreePage} />
              <Route exact path={process.env.PUBLIC_URL + '/paypal'} component={PayPalTest} />

              <Route exact path={process.env.PUBLIC_URL + '/help'} component={HelpPage} />
              <Route exact path={process.env.PUBLIC_URL + '/help-detail'} component={HelpDetailPage} />

              <Route exact path={process.env.PUBLIC_URL + '/schedule'} component={SchedulePage} />
              <Route exact path={process.env.PUBLIC_URL + '/schedule-detail'} component={ScheduleDetailPage} />

              {/* Sub-Pages */}
              <Route exact path={process.env.PUBLIC_URL + '/company'} component={CompanyPage} />
              <Route exact path={process.env.PUBLIC_URL + '/business'} component={BusinessProposalPage} />
              <Route exact path={process.env.PUBLIC_URL + '/user-terms'} component={UserTermsPage} />
              <Route exact path={process.env.PUBLIC_URL + '/personal'} component={PersonalPage} />
              <Route exact path={process.env.PUBLIC_URL + '/site-map'} component={SiteMap} />

              {/* payletter Callback URL */}
              <Route exact path={process.env.PUBLIC_URL + '/pay-result'} component={PayResult} />
              <Route exact path={process.env.PUBLIC_URL + '/auth-result'} component={AuthResult} />

              <Route exact path={process.env.PUBLIC_URL + '/not-found'} component={NotFound} />
              <Route exact component={NotFound} />
            </Switch>
          </Suspense>
        </ScrollToTop>
      </Router>
    </LayoutContextProvider>
  );
}

export default App;
