import React, { useEffect, useState } from 'react';

import ListComponent from './common/ListComponent';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import MenuIcon from '@material-ui/icons/Menu';

import ClearIcon from '@material-ui/icons/Clear';
import { MobileDraWerMenu, MobileHeaderBtn, Menu, Logo } from '../styles/layOutStyles';
import { Flex } from '../styles/globalStyles';
import { USR_ROLE, bodyHiddenToggle, STORE_URL } from '../constants/index';

import { MOBILEMENU } from './Menu';
import Alert from '../helpers/Alert';
import { SwipeableDrawer, IconButton, Collapse } from '@material-ui/core';

import SubLayoutPage from './SubLayoutPage';

import { useHistory } from 'react-router-dom';

const MobileDrawer = props => {
  const history = useHistory();
  const { headerTitle, logoShow, adminSite, userInfo, logOut } = props;

  const [menuValue, setMenuValue] = useState(false);

  const [selectedMenu, setSelectedMenu] = useState('');
  const [selectedSubMenu, setSelectedSubMenu] = useState('');

  const [scrollFocus, setScrollFocus] = useState('');
  const [delay, setDelay] = useState('');

  useEffect(() => {
    if (delay) clearTimeout(delay);
    let _delay;

    if (scrollFocus) {
      _delay = setTimeout(() => {
        scrollFocus.scrollIntoView(true);
      }, 300);

      setDelay(_delay);
    }
  }, [scrollFocus]);

  const goLink = path => {
    menuClose();

    if (path === 'store') {
      window.open(STORE_URL, '_blank');
    } else {
      history.push(process.env.PUBLIC_URL + `${path}`);
    }
  };

  const handleDropdown = (e, data, type) => {
    const { label, pathname, down } = data;

    setScrollFocus(e.target);

    if (!userInfo) {
      if (label === '마이페이지') {
        goLogin();
        return;
      }
    }

    if (pathname) {
      goLink(pathname);
    } else if (down) {
      if (type === 'main') {
        if (label === selectedMenu) {
          setSelectedMenu('');
        } else {
          setSelectedMenu(label);
        }
      } else if (type === 'sub') {
        if (label === selectedSubMenu) {
          setSelectedSubMenu('');
        } else {
          setSelectedSubMenu(label);
        }
      }
    }
  };

  const goLogin = () => {
    Alert('', '로그인이 필요한 서비스입니다. <br /> 로그인 하시겠습니까?', '아니요', '로그인 하러가기', res => {
      if (res) history.push(`/login`);
    });
  };

  const menuOpen = () => {
    bodyHiddenToggle(true);
    setMenuValue(true);
  };

  const menuClose = () => {
    bodyHiddenToggle(false);
    setMenuValue(false);
  };

  return (
    <Flex justifyContent='flex-start'>
      <MobileHeaderBtn>
        <IconButton onClick={menuOpen}>{<MenuIcon fontSize='medium' />}</IconButton>
        {logoShow && <Logo className='headerLogo' />}
      </MobileHeaderBtn>
      <Menu arch={headerTitle} fontSize='22px'>
        {headerTitle}
      </Menu>
      <SubLayoutPage>
        <MobileDraWerMenu active={menuValue}>
          <sub>
            <SwipeableDrawer anchor='left' variant='persistent' open={menuValue} onOpen={menuClose} onClose={menuClose}>
              <article>
                <div>
                  {userInfo ? (
                    <h1>
                      {`${userInfo.name} 님`}
                      <b>{userInfo.email}</b>
                    </h1>
                  ) : (
                    <h1 onClick={() => goLink('/login')}>로그인 하기</h1>
                  )}
                </div>
                <section className='scrollbarRemove'>
                  <ListComponent
                    title={'홈'}
                    titleBold={true}
                    onClick={e => handleDropdown(e, { label: 'home', pathname: 'home' }, 'main')}
                    style={{ margin: '0 3%' }}
                  />
                  {MOBILEMENU.map((menu, idx) => {
                    return (
                      <React.Fragment key={`menu_${idx}`}>
                        <ListComponent
                          id={menu.anchor}
                          title={menu.label}
                          titleBold={true}
                          onClick={e => handleDropdown(e, menu, 'main')}
                          active={menu.label === selectedMenu}
                          style={{ margin: '0 3%' }}
                        />
                        <Collapse
                          in={menu.label === selectedMenu}
                          style={{ borderBottom: '1px solid rgba(232, 232, 232, 0.5)' }}
                          timeout='auto'
                          unmountOnExit
                        >
                          <aside>
                            {menu?.down &&
                              menu?.down.map((down, downIndex) => (
                                <React.Fragment key={`downmenu_${downIndex}`}>
                                  <ListComponent
                                    title={down.label}
                                    titleBold={false}
                                    fontSize='13px'
                                    padding='0 32px'
                                    onClick={e => handleDropdown(e, down, 'sub')}
                                    active={down.label === selectedSubMenu}
                                  />
                                  <Collapse
                                    in={down.label === selectedSubMenu}
                                    style={{ borderBottom: '1px solid rgba(232, 232, 232, 0.5)' }}
                                  >
                                    <nav>
                                      {down?.down &&
                                        down.down.map((sub, downIndex) => (
                                          <ListComponent
                                            key={`subdownmenu_${downIndex}`}
                                            title={sub.label}
                                            titleBold={false}
                                            fontSize='12px'
                                            padding='0 37px'
                                            onClick={e => handleDropdown(e, sub, 'main')}
                                          />
                                        ))}
                                    </nav>
                                  </Collapse>
                                </React.Fragment>
                              ))}
                          </aside>
                        </Collapse>
                      </React.Fragment>
                    );
                  })}
                  {userInfo && userInfo?.role !== USR_ROLE && (
                    <ListComponent title={'관리자 메뉴'} titleBold={true} onClick={adminSite} style={{ margin: '0 3%' }} />
                  )}
                  <div id='scrollFocus' />
                </section>
                {userInfo && (
                  <h2 onClick={logOut}>
                    <ExitToAppIcon /> &nbsp; 로그아웃
                  </h2>
                )}
              </article>
            </SwipeableDrawer>
          </sub>
          {menuValue && (
            <div className='mobileDrawerCloseBtn' onClick={menuClose}>
              <IconButton>{<ClearIcon />}</IconButton>
            </div>
          )}
        </MobileDraWerMenu>
      </SubLayoutPage>
    </Flex>
  );
};

export default MobileDrawer;
