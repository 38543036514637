import { FETCH_PROGRAM_SUCCESS } from '../actions/programActions';

const initState = {
  program: [],
  step: 0,
  title: [],
};

const programReducer = (state = initState, action) => {
  if (action.type === FETCH_PROGRAM_SUCCESS) {
    return {
      ...state,
      program: action.payload,
      step: action.step,
      title: action.title,
    };
  }

  return state;
};

export default programReducer;
