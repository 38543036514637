import styled, { css, keyframes } from 'styled-components';
import { smLayout, mdLayout, xlLayout, uxLayout, dot } from './homeStyles';

let herderHeight = '72px';
let bottomHeight = '66px';

const fadeInDown = keyframes`
  from {
    opacity:0;
    -webkit-transform: translatey(-20px);
    -moz-transform: translatey(-20px);
    -o-transform: translatey(-20px);
    transform: translatey(-20px);
  }
  to {
    opacity:1;
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -o-transform: translatey(0);
    transform: translatey(0);
  }
`;

export const HeaderStyled = styled.header`
  padding: 24px 13%;

  ${xlLayout} {
    padding: 24px 3%;
    font-size: 0.92em;
  }

  ${uxLayout} {
    padding: 24px 20%;
  }

  ${props =>
    props.mobile &&
    css`
      background: ${props => props.theme.background};
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
      padding: 12px 5%;
      z-index: 8000;

      ${props =>
        props.headerOpen &&
        css`
          position: fixed !important;
          z-index: 8000 !important;
          top: 0 !important;
          left: 0 !important;
          padding: 3px 5%;

          width: 100% !important;

          transition: all 0.3s ease 0s;
          animation: ${fadeInDown} 0.5s;

          background: ${props => props.theme.background} !important;
          box-shadow: 0 0 25px 0 rgba(0, 0, 0, 0.06) !important;

          & button {
            color: ${props => props.theme.text} !important;
          }

          & .headerLogo {
            top: 26px !important;
          }
        `}
    `}
`;

export const MobileHeaderBtn = styled.div`
  & button {
    color: ${props => props.theme.text};
    padding: 9px;
  }

  & svg {
    font-size: 28px;
  }
`;

export const MobileDraWerMenu = styled.div`
  & .MuiPaper-root {
    width: 80%;
    background-color: ${props => props.theme.background};
    padding: 0;
    z-index: 34375277;
    overflow: hidden;
  }

  ${props =>
    props.active
      ? css`
          & sub {
            width: 100vw;
            height: 100vh;
            position: fixed;
            top: 0px;
            left: 0px;
            z-index: 34375277;
            background-color: rgba(0, 0, 0, 0.3);
          }

          & .mobileDrawerCloseBtn {
            width: 20%;
            height: 100%;
            position: fixed;
            top: 0%;
            right: 0%;
            z-index: 34375278;
            text-align: center;

            & > button {
              color: #ffffff;
              font-size: 26px;
              margin-top: 10px;
            }
          }

          & section {
            height: 450px;
            margin: 3% 0;
            overflow-x: hidden;
            overflow-y: auto;
          }

          & article > div > h1 {
            color: ${props => props.theme.text};
            padding: 15px;
            font-weight: 700;
            font-size: 21px;
            margin: 27px 0 0;
            display: flex;
            justify-content: space-between;
            align-items: center;

            & > b {
              color: ${props => props.theme.button};
              font-size: 13px;
              font-weight: 300;
            }
          }

          & article > h2 {
            color: ${props => props.theme.subText};
            padding: 15px;
            font-size: 16px;
            position: fixed;
            bottom: 0;
            display: flex;
            align-items: center;
            justify-content: center;

            & > svg {
              color: ${props => props.theme.cancelText};
              font-size: 20px;
            }
          }

          & aside {
            background-color: ${props => props.theme.secondaryBackground};
          }
          & nav {
            background-color: ${props => (props.theme.theme === 'dark' ? 'rgb(0 0 0 / 50%)' : '#f5f5f5')};
          }
        `
      : css`
          & h1 {
            font-size: 0px;
          }
        `}
`;

export const MainStyled = styled.main`
  margin-bottom: 0px;
  min-height: 80vh;
  overflow-x: hidden;
  // min-height: 100%;

  ${props =>
    props.home &&
    css`
      margin-top: 0 !important;
    `}

  ${smLayout} {
    min-height: calc(100vh - 80px);
    margin-top: ${herderHeight};
    margin-bottom: ${bottomHeight};

    ${props =>
      props.search &&
      css`
        margin-top: 130px;
      `}
  }
`;

export const FooterStyled = styled.footer`
  font-size: 14px;
  height: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.theme.theme === 'dark' ? '#191919' : '#fbfbfb')};
  flex-direction: column;
`;

export const FooterBannerArea = styled.nav`
  width: 100%;
  height: 51px;
  font-size: 14px;
  background-color: ${props => (props.theme.theme === 'dark' ? '#171819' : '#fbfbfb')};
  overflow: hidden;
  border-bottom: 1px solid ${props => (props.theme.theme === 'dark' ? '#3d3d3d' : '#0a0a000d')};

  & > div {
    width: 100%;
    text-align: center;
  }

  & #noticeBannerFilcking {
    height: 51px !important;
    padding: 0 20%;
    position: relative;
  }

  & .noticeBannerItems {
    width: 100%;
    height: 51px;
    display: flex !important;
    justify-content: flex-start;

    & > h1 {
      font-size: 15px;
      color: ${props => props.theme.cancelText};
      text-align: right;
      padding-right: 5%;
    }

    & > h2 {
      font-size: 14px;
      cursor: pointer;
      color: ${props => props.theme.text};
      text-align: left;
    }
  }

  & #noticeBannerCamera {
    transition: all 2s;
  }

  & #noticeBannerPagination {
    position: absolute;
    top: 32%;
    right: 20%;

    & p {
      color: #777777;
    }
  }

  & #noticeBannerBtnGroup {
    position: absolute;
    top: 11%;
    right: 18.7%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    & > span {
      position: relative;
      width: 0px;
      height: 0px;
      margin: 5px 0;
      cursor: pointer;
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;
    }
    & > span:nth-child(1) {
      border-bottom: 5px solid ${props => props.theme.cancelText};
    }
    & > span:nth-child(2) {
      border-top: 5px solid ${props => props.theme.cancelText};
    }
  }
`;

export const FooterMobileStyled = styled.footer`
  width: 100%;
  height: 65px;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid ${props => props.theme.airLine};
  position: fixed;
  bottom: 0;
  z-index: 7000;

  background-color: ${props => props.theme.background};
`;

export const MobileFooterMenu = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;
  width: 100%;

  & button {
    padding: 6px;
  }

  & div > div {
    text-align: center;
    font-size: 12px;
    color: ${props => props.theme.subText};
    word-break: keep-all;
  }

  ${props =>
    props.active &&
    css`
      & div > div {
        color: ${props => props.theme.button};
        font-weight: bold;
      }
    `}
`;

export const Menu = styled.div`
  font-size: 1.1em;
  margin: 0 12px;
  color: ${props => props.theme.text};
  font-weight: bold;
  cursor: pointer;
  position: relative;

  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize} !important;
    `}

  ${props =>
    props.arch === 'ON-AIR' &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0%;
        right: -10%;
        width: 5px;
        height: 5px;
        background-color: #f33;
        border-radius: 100%;
        animation: ${dot} 1s infinite alternate ease;
      }
    `}



  ${xlLayout} {
    font-size: 1em;
  }
`;

export const Logo = styled.div`
  background-position: center;
  width: 79px;
  height: 42px;
  margin-right: 20px;
  background-repeat: no-repeat;
  cursor: pointer;
  background-size: 100%;
  object-fit: cover;
  background-image: url(/assets/Ftv/Ftv_Logo.png);
`;

export const BannerJoinHeader = styled.article`
  width: 100%;
  position: relative;
  margin-bottom: 25px;

  & header {
    width: 100%;
    position: absolute;
    top: 0%;
    z-index: 7500;
    background: transparent;

    & .headerSearch_Input {
      background-color: #3f444b;

      & input {
        color: #ffffff;
      }

      & svg {
        color: #ffffff;
      }
    }

    & div {
      color: #ffffff;
    }
  }

  & #bannerSkeleton {
    background-color: rgba(0, 0, 0, 0.3);
  }

  ${smLayout} {
    & header {
      & button {
        color: #ffffff;
      }

      & .headerLogo {
        width: 62px;
        height: 100%;
        top: 35px;
        left: 50%;
        position: absolute;
        transform: translate(-35%, -50%);
      }
    }
  }
`;

// box-shadow: inset 0 -200px 100px -100px ${props => props.theme.background};

export const BannerblurBox = styled.div`
  width: 100%;
  color: #ffffff;
  position: absolute;

  & .slider-content-5 {
    z-index: 2;
    position: absolute;
    padding: 50px 17% 10px;
    text-align: left;
    bottom: 10px;
  }

  & .banner-item-effect {
    width: 100%;
    height: 250px;
    position: absolute;
    bottom: 0;
    z-index: 1;

    & img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3 {
    font-size: 27px !important;
    margin: 0 0 7px;
    letter-spacing: 0.5px;
    font-weight: bold;
    text-shadow: 2px 2px 7px #555555;
  }
  h1 {
    font-size: 15px !important;
    font-weight: bold;
    line-height: 1.2 !important;
    margin: 0 0 13px;
    letter-spacing: 0.5px;
    text-shadow: 2px 2px 7px #555555;
  }
  span {
    font-size: 15px !important;
    line-height: 1.2 !important;
    margin: 0 0 10px;
    letter-spacing: 0.5px;
    text-shadow: 2px 2px 7px #555555;
    width: 220px;
    display: block;
    word-break: break-word;
  }
  p {
    font-size: 14px !important;
    text-shadow: 2px 2px 7px #555555;
    margin: 0 0 15px;
  }

  button {
    width: 100px;
    height: 33px;
    padding: 0;
    margin: 0 10px 0 0;
  }

  ${xlLayout} {
    & .slider-content-5 {
      padding: 50px 10% 20px;
    }
  }

  ${smLayout} {
    box-shadow: inset 0 -230px 48px -182px ${props => props.theme.background};

    & .slider-content-5 {
      width: 100vw;
      padding: 50px 17% 52px;
      text-align: center;
    }

    & .banner-item-effect {
      height: 150px;
    }

    h3 {
      font-size: 20px !important;
      margin: 0;
    }
    p {
      font-size: 15px !important;
    }
  }
`;

export const ScrollBox = styled.nav`
  position: fixed;
  bottom: 12%;
  right: 10%;
  z-index: 99999;

  ${smLayout} {
    right: 4%;
  }
`;

export const ScrollTopButton = styled.button`
  background-color: ${props => props.theme.button};
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: none;
  margin: 11px 0;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 0;
  visibility: hidden;
  transition: opacity 500ms, visibility 500ms;

  svg {
    color: #fff;
  }

  ${props =>
    props.active &&
    css`
      opacity: 1;
      visibility: unset;
      transition: opacity 500ms, visibility 500ms;
    `};
`;

export const SwitchButton = styled.label`
  position: relative;
  display: flex;
  width: 100px;
  height: 34px;
  border: 1px solid #777777;
  justify-content: center;
  align-items: center;
  color: #777777;
  border-radius: 30px;
  cursor: pointer;

  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  svg {
    width: 17px;
    height: 17px;
    object-fit: cover;
    margin-right: 5px;
    color: #aaaaaa;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span > p {
    font-size: 14px;
    font-weight: 900;
  }

  ${smLayout} {
    background-color: ${props => props.theme.background};
    border: 2px solid ${props => props.theme.airLine} !important;

    width: 44px;
    height: 44px;
    border-radius: 50%;
    border: none;
    margin: 0;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    padding: 0;

    span {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      object-fit: cover;
      margin: 0;
    }
  }
`;

export const DialogBg = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 80808080;
  overflow: hidden;

  & svg {
    color: ${props => props.theme.button};
  }

  opacity: 0;
  visibility: hidden;
  transition: opacity 300ms, visibility 300ms;

  ${props =>
    props.backColorOff &&
    css`
      background-color: transparent;
    `}

  ${props =>
    props.open &&
    css`
      opacity: 1;
      visibility: unset;
      transition: opacity 300ms, visibility 300ms;
    `}
`;

export const DialogBox = styled.div`
  width: 515px;
  height: auto;
  padding: 30px 25px;
  background-color: ${props => props.theme.background};
  position: relative;
  z-index: 90909090;

  ${smLayout} {
    width: 80vw;
  }
`;

export const NoDataFieldBody = styled.article`
  width: 100%;
  height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & > span {
    font-size: 15px;
    color: ${props => props.theme.subText};
  }
`;

export const IconGroupBody = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 200px;
  padding: 0 15%;
  border-top: 1px solid ${props => props.theme.airLine};

  ${smLayout} {
    padding: 0 5%;
  }

  ${uxLayout} {
    padding: 0 20%;
  }
`;

export const ProgramSponsor = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 20px 0;

  & > span {
    display: block;
    width: ${100 / 4 - 1}%;
    height: 85px;
    margin: 3px;
    border: 1px solid ${props => props.theme.line};
    cursor: pointer;
  }

  & img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }

  ${smLayout} {
    & > span {
      width: ${100 / 2 - 1}%;
      margin: 1px;
    }
  }
`;

export const DicodeDialog = styled.div`
  width: 100%;
  padding: 5px;
  text-align: center;
  border: 1px solid ${props => props.theme.line};
  position: relative;

  & svg {
    font-size: 2em;
    color: ${props => props.theme.button};
    border: 1px solid ${props => props.theme.button};
    border-radius: 50%;
    padding: 5px;
  }

  & p {
    margin: 12px 0;
    font-size: 16px;
    font-weight: bold;
    color: ${props => props.theme.text};
  }

  & span {
    display: block;
    margin: 12px 0;
    font-size: 12px;
    color: ${props => props.theme.text};
  }

  & b {
    color: red;
  }

  & u {
    text-decoration: underline;
    cursor: pointer;
  }
`;

export const JWTCookieCheckLayOut = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 52412;
`;

export const LazyImgContainer = styled.nav`
  width: 100%;
  height: 100%;
  position: relative;

  & > div {
    position: absolute;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.25), rgba(255, 255, 255, 0));
    z-index: 1;
  }

  .lazy_images {
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 420ms ease 0s;
    visibility: hidden;
    object-fit: fill;
    position: absolute;
    top: 0;
    left: 0;
  }

  .lazy_images.active {
    opacity: 1;
    visibility: visible;
  }
`;
