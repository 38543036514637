import styled, { css } from 'styled-components';
import { smLayout, xlLayout, uxLayout, dot } from './homeStyles';

export const ButtonTitle = styled.span`
  display: flex;
  align-items: center;
  position: relative;

  & .title {
    font-weight: bold;
    font-size: 23px;
    color: ${props => props.theme.text};
    position: relative;

    ${props =>
      props.fontSize &&
      css`
        font-size: ${props.fontSize};
      `};

    ${props =>
      props.titleColor &&
      css`
        color: ${props => props.theme.button};
      `};

    ${props =>
      props.style &&
      css`
        ${props.style}
      `};

    ${props =>
      props.point &&
      css`
        &::after {
          content: '';
          position: absolute;
          top: 0%;
          right: -5%;
          width: 5px;
          height: 5px;
          background-color: #f33;
          border-radius: 100%;
          animation: ${dot} 1s infinite alternate ease;
        }
      `};
  }

  & > span {
    margin-left: 3px;
    color: ${props => props.theme.subText};
  }

  & > .blue {
    color: ${props => props.theme.button};
  }

  ${props =>
    props.flex &&
    css`
      flex: ${props => props.flex};
    `};

  ${smLayout} {
    font-size: 20px;
  }
`;

export const Button = styled.button`
  background-color: ${props => props.theme.button};
  color: #ffffff;
  border: none;
  min-width: 64px;
  padding: 9px 16px;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  margin: 0 5px;
  width : {width}

  ${smLayout} {
    padding: 10px 10px;
    font-size: 13px;
    margin: 0 2px;
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${props =>
    props.paddingTop &&
    css`
      padding-top: ${props.paddingTop};
    `}
  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};
  ${props =>
    props.Cancel &&
    css`
      background-color: transparent;
      border: 1px solid #aaaaaa;
      color: #aaaaaa;
    `};
  ${props =>
    props.type === 'main' &&
    css`
      background-color: transparent !important;
      border: 1px solid #ffffff !important;
      color: #ffffff !important;
    `};
  ${props =>
    props.type === 'rec' &&
    css`
      background-color: transparent !important;
      border: 1px solid #232323 !important;
      color: #232323 !important;
    `};
  ${props =>
    props.disabled &&
    css`
      background-color: transparent;
      border: 1px solid #aaaaaa;
      color: #aaaaaa;
    `};

  ${props =>
    props.bannerItem &&
    css`
      background-color: ${props => (props.theme.theme === 'dark' ? '#4478fe' : '#203058')};
    `};

  ${props =>
    props.boxShadow &&
    css`
      box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
    `};

  ${props =>
    props.type === 'main' &&
    css`
      background-color: #ffffff;
      border: 1px solid #ffffff;
      color: #2a59f2;
    `};
  ${props =>
    props.type === 'rec' &&
    css`
      background-color: #232323;
      border: 1px solid #232323;
      color: #ffffff;
    `};
`;

export const InPutBox = styled.div`
  width: 100%;
  min-height: 56px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  // padding: 0 10px;

  & span {
    color: #ff0000;
    font-size: 11px;
    margin: 3px 0;
    display: block;
    white-space: pre-line;
  }

  ${props =>
    props.selectBox &&
    css`
      position: absolute;
      left: 1px;
      width: 70%;

      & > input {
        background-color: ${props => (props.theme.theme === 'dark' ? '#171819' : '#ffffff')};
        padding: 5px 6px;
        border: none;
        font-size: 14px;
      }
      & > input:focus {
        border: none;
      }
    `}
`;

export const Slider = styled.span`
  width: 100%;
  position: relative;
  cursor: pointer;

  & .slider-rail {
    width: 100%;
    background-color: #aaaaaa;
    display: block;
    height: 8px;
    border-radius: 15px;
  }

  & .slider-track {
    width: ${props => props.val}%;
    background-color: #4475fe;
    height: 8px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 15px;
  }
`;

export const Input = styled.input`
  width: 100%;
  outline: none;
  border: none;
  padding: 8px 7px;
  color: ${props => props.theme.text};
  box-sizing: border-box;

  ${props =>
    props.variant === 'standard' &&
    css`
      background-color: transparent;
      border-bottom: 1px solid ${props.theme.line};
      border-radius: 0;

      &:focus {
        transition: border 0.3s;
        border-bottom: 1px solid ${props.theme.button};
      }
    `}
  ${props =>
    props.variant === 'outlined' &&
    css`
      border: 1px solid ${props.theme.theme === 'dark' ? '#171819' : props.theme.line};
      background-color: ${props.theme.theme === 'dark' ? '#171819' : 'transparent'};
      border-radius: 0;

      &:focus {
        transition: border 0.3s;
        border: 1px solid ${props.theme.button};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      color: #555555;
      opacity: 0.5;
    `}


  ${smLayout} {
    font-size: 13px;
  }
`;

export const TextArea = styled.textarea`
  width: 100%;
  outline: none;
  border: none;
  padding: 8px 7px;
  color: ${props => props.theme.text};
  box-sizing: border-box;
  resize: none;

  border: 1px solid ${props => (props.theme.theme === 'dark' ? '#171819' : props.theme.line)};
  background-color: ${props => (props.theme.theme === 'dark' ? '#171819' : 'transparent')};
  border-radius: 0;

  &:focus {
    transition: border 0.3s;
    border: 1px solid ${props => props.theme.button};
  }
    ${props =>
      props.disabled &&
      css`
        color: #555555;
        opacity: 0.5;
      `}
  }
`;

export const SelectBox = styled.div`
  width: 100%;
  min-height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 10px;
  position: relative;
  flex-direction: row;

  font-size: 14px;
  color: ${props => props.theme.text};

  & span {
    width: 85%;
  }
  & i {
    width: 15%;

    & svg {
      font-size: 1em;
      height: 15px;
    }
  }

  border: 1px solid ${props => (props.theme.theme === 'dark' ? '#171819' : props.theme.line)};
  background-color: ${props => (props.theme.theme === 'dark' ? '#171819' : 'transparent')};

  ${props =>
    props.open &&
    css`
      transition: border 0.3s;
      border: 1px solid ${props.theme.button};
    `}
`;

export const Select = styled.ul`
  width: 100%;
  max-height: 250px;
  position: absolute;
  z-index: 500;
  font-size: 14px;
  top: 40px;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;

  border: 1px solid ${props => (props.theme.theme === 'dark' ? '#171819' : props.theme.line)};
  background-color: ${props => (props.theme.theme === 'dark' ? '#171819' : '#FFFFFF')};

  & > li:last-child {
    border-bottom: none;
  }
`;

export const SelectItem = styled.li`
  width: 100%;
  outline: none;
  border: none;
  box-sizing: border-box;
  padding: 8px 10px;
  border-bottom: 1px solid ${props => props.theme.subLine};
  cursor: pointer;

  &:hover {
    background-color: rgb(0, 0, 0, 0.2);
  }

  ${props =>
    props.select &&
    css`
      color: ${props => props.theme.button};
    `}
`;

export const Radio = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.text};
  font-size: 14px;
  cursor: pointer;
  padding: 5px 0;
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  & div {
    width: 16px;
    height: 16px;
    position: relative;
    border: 1px solid ${props => props.theme.line};
    border-radius: 50%;
  }

  & span {
    margin-left: 10px;
  }

  ${props =>
    props.choice &&
    css`
      & div:after {
        content: '';
        width: 9px;
        height: 9px;
        border: 1px solid;
        display: block;
        background-color: #4475fe;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
      }
    `}

  &:hover {
    & div {
      opacity: 0.7;
    }
  }

  ${smLayout} {
    width: ${100 / 3}%;
    justify-content: flex-start;

    & span {
      margin-left: 5px;
      font-size: 13px;
    }
  }
`;

export const ToggleBtn = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  & svg {
    width: 22px;
    height: 24px;
    color: ${props => props.theme.subText};
    padding-right: 3px;
  }

  & span {
    color: ${props => props.theme.subText};
    font-size: 13px;
  }

  ${props =>
    props.column &&
    css`
      margin: 0 8px;
      flex-direction: column;
      align-items: center;

      & svg {
        width: 25px;
        height: 27px;
        color: ${props => props.theme.subText};
        padding: 3px 0;
      }
    `};

  ${props =>
    props.active &&
    css`
      & .starIcon {
        color: #e8b325;
      }
      & .bellIcon {
        color: #4478fe;
      }
    `};

  ${smLayout} {
    ${props =>
      props.column &&
      css`
        margin: 0 3px;

        & svg {
          width: 25px;
          height: 23px;
        }
      `};
  }
`;

export const Tabs = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 0 15%;
  border-bottom: 1px solid ${props => props.theme.line};

  ${uxLayout} {
    padding: 0px 20%;
  }

  ${smLayout} {
    padding: 0px 5%;
    margin-bottom: 20px;
    overflow-y: hidden;
    overflow-x: auto;
  }

  ${props =>
    props.option === 'filter' &&
    css`
      padding: 0;
      border-bottom: none;

      & > button:nth-child(1) {
        border-right: 1px solid ${props => props.theme.subText};
      }

      ${smLayout} {
        padding: 0px;
        margin-bottom: 0px;
      }
    `}
  ${props =>
    props.variant === 'outlined' &&
    css`
      justify-content: space-between;
      border-bottom: none;

      ${smLayout} {
        padding: 0px;
      }
    `}
  ${props =>
    props.variant === 'styleTab' &&
    css`
      position: relative;

      &:after {
        background: ${props => props.theme.line};
        content: '';
        position: absolute;
        top: 30%;
        left: 90px;
        width: 1px;
        height: 16px;

        ${smLayout} {
          left: 50%;
        }
      }
    `}
  ${props =>
    props.dark &&
    css`
      & > button:nth-child(1) {
        border-right: 1px solid #aaaaaa;
      }
    `}
`;

export const Tab = styled.button`
  display: flex;
  align-items: center;
  color: ${props => props.theme.subText};
  position: relative;
  padding: 22px 16px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  outline: none;
  word-break: keep-all;
  white-space: nowrap;
  justify-content: center;

  &:after {
    content: '';
    position: absolute;
    width: 0%;
    bottom: 0;
    left: 0;
  }

  ${smLayout} {
    padding: 15px 5px;
    font-size: 15px;
    margin: 0 5px;
  }

  ${props =>
    props.option === 'filter' &&
    css`
      color: ${props => props.theme.subText};
      font-size: 13px;
      padding: 0px 16px;
      &:after {
        display: none;
      }

      ${smLayout} {
        padding: 0px 10px;
        margin: 0;
      }
    `}

  ${props =>
    props.variant === 'outlined' &&
    css`
      width: ${props => props.tabCnt && 100 / props.tabCnt}%;
      padding: 18px 16px;
      border-top: 1px solid ${props => props.theme.line};
      border-bottom: 1px solid ${props => props.theme.button};

      ${smLayout} {
        padding: 20px ${props => props.tabCnt && 100 / props.tabCnt}%;
        margin: 0;
      }
    `}

  ${props =>
    props.variant === 'styleTab' &&
    css`
      ${smLayout} {
        border-bottom: 1px solid ${props => props.theme.line};
        margin: 0;
      }
    `}


    ${props =>
    props.dark &&
    css`
      color: #aaaaaa;

      ${props =>
        props.active &&
        css`
          color: #4475fe !important;
        `}
    `}


  ${props =>
    props.basicTab &&
    css`
      color: ${props => props.theme.subText};
    `}

  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.button};
      font-weight: 800;
      &:after {
        bottom: -2px;
        border-bottom: 3px solid;
        transition: width 0.2s;
        width: 100%;
      }
      ${props =>
        props.activeLineHide &&
        css`
          &:after {
            width: 0%;
          }
        `}

      ${props =>
        props.option === 'filter' &&
        css`
          & svg {
            color: ${props => props.theme.button} !important;
            font-size: 15px;
            margin-right: 5px;
          }
        `}
        
        ${props =>
        props.variant === 'outlined' &&
        css`
          width: ${props => props.tabCnt && 100 / props.tabCnt}%;
          padding: 18px 16px;
          border-top: 1px solid ${props => props.theme.button};
          border-left: 1px solid ${props => props.theme.button};
          border-right: 1px solid ${props => props.theme.button};

          border-bottom: none;

          &:after {
            border-bottom: none;
          }
        `}

      ${props =>
        props.basicTab &&
        css`
          color: ${props => props.theme.text};
          font-weight: bold;
        `}
    `}
`;

export const List = styled.div`
  color: ${props => props.theme.text};
  border-bottom: 1px solid ${props => props.theme.airLine};
  min-height: 45px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > h1 {
    font-size: 15px;
    margin: 0;
    ${props =>
      props.titleBold &&
      css`
        font-weight: bold;
      `}
  }
  & > h3 {
    font-size: 16px;
  }
  & > p {
    color: #aaaaaa;
    font-size: 13px;
  }

  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `}
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `}
  ${props =>
    props.link &&
    css`
      cursor: pointer;
    `}
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `}
    
  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.button};
      font-weight: bold;
    `}
  ${props =>
    props.sort &&
    css`
      justify-content: flex-start;
      & > h1 {
        flex: 1;
      }
      & > h3 {
        flex: 2;
      }
      & > p {
        flex: 1;
      }

      ${smLayout} {
        & > h1 {
          font-size: 15px;
        }
        & > h3 {
          font-size: 13px;
        }
        & > p {
          font-size: 13px;
        }
      }
    `}
`;

export const LineText = styled.label`
  width: 100%;
  color: #aaaaaa;
  display: flex;
  align-items: center;
  margin: 28px 0px;

  &:after,
  &:before {
    content: '';
    flex-grow: 1;
    background: ${props => props.theme.line};
    height: 2px;
    font-size: 0px;
    line-height: 0px;
    display: inline-block;
  }

  &:after {
    margin-left: 16px;
  }
  &:before {
    margin-right: 16px;
  }

  ${smLayout} {
    font-size: 13px;
  }
`;

export const CheckBox = styled.div`
  display: flex;
  padding: 3px 5px;
  align-items: center;
  cursor: pointer;

  & input {
    display: none;
  }

  & span {
    position: relative;
    width: 20px;
    height: 20px;
    display: block;
    background-color: #cccccc;

    & svg {
      width: 100%;
      height: 100%;
      position: absolute;
      color: ${props => (props.theme.theme === 'dark' ? '#1e1f21' : '#ffffff')};
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }

  &:hover svg {
    opacity: 0.7;
  }

  & label {
    color: ${props => props.theme.text};
    margin-left: 7px;
    cursor: pointer;
    flex: 1;
  }

  ${props =>
    props.active &&
    css`
      & span {
        background-color: ${props => props.theme.button};

        &:after {
          color: #ffffff;
        }
      }
    `}

  ${props =>
    props.orderChange &&
    css`
      & span {
        order: 2;
      }
      & label {
        order: 1;
        margin-left: 0px;
        margin-right: 7px;
      }
    `}
  ${props =>
    props.size === 'small' &&
    css`
      & span {
        width: 13px;
        height: 13px;
      }
      & label {
        font-size: 14px;
      }
    `}
  ${props =>
    props.size === 'medium' &&
    css`
      & span {
        width: 17px;
        height: 17px;
      }
      & label {
        font-size: 15px;
      }
    `}
`;

export const Toggle = styled.div`
  display: flex;
  padding: 3px 5px;
  margin: 3px 0;
  align-items: center;
  cursor: pointer;

  & input {
    display: none;
  }

  & span {
    position: relative;
    width: 40px;
    height: 22px;
    border-radius: 15px;
    display: block;
    background-color: ${props => props.theme.disabledText};

    &:after {
      content: '';
      display: block;
      background-color: #ffffff;
      width: 16px;
      height: 16px;
      border-radius: 50%;
      margin: 0 4px;
      position: absolute;
      top: 11%;
      left: 0%;
      transition: left 0.1s;
    }
    &:hover {
      opacity: 0.9;
    }
  }

  & label {
    color: ${props => props.theme.text};
    margin-right: 7px;
    cursor: pointer;
  }

  ${props =>
    props.active &&
    css`
      & span {
        background-color: ${props => props.theme.button};

        &:after {
          left: 40%;
          transition: left 0.1s;
        }
      }
    `}

  ${props =>
    props.size === 'small' &&
    css`
      & span {
        width: 13px;
        height: 13px;
        &:after {
          font-size: 15px;
        }
      }
      & label {
        font-size: 14px;
      }
    `}
  ${props =>
    props.size === 'medium' &&
    css`
      & span {
        width: 17px;
        height: 17px;
        &:after {
          font-size: 15px;
        }
      }
      & label {
        font-size: 14px;
      }
    `}
`;

export const TabSearchStyled = styled.div`
  width: 300px;
  display: flex;
  justify-content: space-between;

  & select {
    width: 24%;
    font-size: 12px;
    padding: 6px;
    border: 1px solid ${props => props.theme.line};
    color: ${props => props.theme.text};
    background-color: transparent;
    outline: none;
  }

  & span {
    background-color: transparent;
    width: 74%;
    font-size: 12px;
    border: 1px solid ${props => props.theme.line};
    display: flex;
    justify-content: end;
    align-items: center;

    & input {
      width: 100%;
      height: 35px;
      border: none;
      outline: none;
      padding: 0 12px;
      color: ${props => props.theme.text};
      background-color: transparent;
    }
    & svg {
      width: 22px;
      height: 16px;
      margin: 10px;
      color: ${props => props.theme.text};
    }
  }

  ${props =>
    props.write &&
    css`
      width: 400px;
      & select {
        width: 21%;
      }
      & span {
        width: 49%;
      }
      & button {
        width: 25%;
        font-size: 13px;
      }
    `}

  ${smLayout} {
    width: 100%;
    margin: 10px 0;
    & span {
      & input {
        height: 40px;
      }
      & svg {
        width: 22px;
        height: 17px;
      }
    }
  }
`;

export const HeaderSearch = styled.nav`
  width: 140px;
  height: 35px;
  position: relative;

  ${xlLayout} {
    width: 110px;
  }

  & .headerSearch_Input {
    position: absolute;
    top: 0;
    z-index: 10;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: ${props => props.theme.line};

    padding: 12px 13px;
    border-radius: 20px;
    font-size: 13px;

    & input {
      width: 90%;
      padding: 5px 5px 5px 0;
      outline: none;
      border: none;
      background-color: transparent;
      color: ${props => props.theme.text};
    }

    & svg {
      width: 16px;
      height: 16px;
      cursor: pointer;
      color: ${props => props.theme.text};
    }

    &:hover {
      box-shadow: 1px 1px 2px 1px ${props => (props.theme.theme === 'dark' ? `#313131` : 'rgb(0 0 0/ 0.2)')};
    }
  }

  ${props =>
    props.showAction &&
    css`
      & .headerSearch_Input {
        box-shadow: 1px 1px 2px 1px ${props => (props.theme.theme === 'dark' ? `#313131` : 'rgb(0 0 0/ 0.2)')};
      }
    `}

  & .headerSearch_AutoComplete {
    background-color: ${props => props.theme.background};
    border-radius: 20px;
    position: absolute;
    width: 230px;
    font-size: 14px;
    text-align: left;
    top: 45px;
    right: 0px;
    padding: 8px 0;
    z-index: 3141592;
    overflow: hidden;
    box-shadow: 1px 1px 2px 1px ${props => (props.theme.theme === 'dark' ? `#313131` : 'rgb(0 0 0/ 0.2)')};

    & li {
      color: ${props => props.theme.text};
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 7px 13px;

      & p {
        word-break: break-all;
        white-space: normal;
        margin-left: 10px;
        flex: 1;
      }
    }

    & .autoComplete_Icon {
      color: #ffffff;
      width: 24px;
      height: 24px;
      border-radius: 50%;
      background-color: #4b535f;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    & .autoComplete_select {
      background-color: ${props => props.theme.secondaryBackground};
    }

    & li:hover {
      background-color: ${props => props.theme.secondaryBackground};
    }
  }
`;

export const RecordDeleteBtn = styled.div`
  display: flex;
  align-items: center;

  & svg {
    padding: 0;
    width: 18px;
    height: 18px;
    color: #aaaaaa;
    cursor: pointer;
  }

  & button {
    font-size: 12px;
    padding: 0;
    padding-right: 10px;
    background-color: transparent;
    color: #aaaaaa;
    border: none;
    box-shadow: none;
    word-break: keep-all;
    white-space: nowrap;
  }
`;
