/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'ap-northeast-2',
  aws_cognito_region: 'ap-northeast-2',
  aws_cognito_identity_pool_id: 'ap-northeast-2:af93f038-5760-4353-b165-7265014bf2dd',
  aws_user_pools_id: 'ap-northeast-2_pYvmZY5mC',
  aws_user_pools_web_client_id: '3j0h0qdmnnfrprv133b0f190p7',
  aws_user_files_s3_bucket: 'file.eftv.site',
  aws_user_files_s3_bucket_region: 'ap-northeast-2',
  aws_access_key: 'AKIAQ4ZH466RJ5CWQ6PY',
  aws_secret_key: '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',
};

export default awsmobile;
