import { FETCH_BANNER_SUCCESS } from '../actions/bannerActions';

const initState = {
  banner: [],
};

const bannerReducer = (state = initState, action) => {
  if (action.type === FETCH_BANNER_SUCCESS) {
    return {
      ...state,
      banner: action.banner,
    };
  }

  return state;
};

export default bannerReducer;
