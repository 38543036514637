import axiosInstance from '../../constants/AxiosInstance';
import { API_URL } from '../../constants';

class infoService {
  getInfo() {
    return axiosInstance.get(`${API_URL}/info`, { headers: { Pragma: 'no-cache' } });
  }
}

export default new infoService();
