import axiosInstance from '../../constants/AxiosInstance';
import { API_URL } from '../../constants';

class UserService {
  getUserInfo(id) {
    if (!id) return;

    return axiosInstance.get(`${API_URL}/user/by/${id}`, { headers: { Pragma: 'no-cache' } });
  }

  getUserActionInfo(id) {
    if (!id) return;

    return axiosInstance.get(`${API_URL}/userAction/my/${id}`, { headers: { Pragma: 'no-cache' } });
  }
}

export default new UserService();
