export const RIGHTMENU = [
  {
    label: '실시간 TV',
    anchor: 'ON-AIR',
    pathname: '/onair',
  },
  {
    label: '프로그램',
    anchor: 'vod',
    pathname: '/vod?tab=0&page=1',
  },
  {
    label: '커뮤니티',
    anchor: 'community',
    pathname: '/community?tab=0',
  },
  {
    label: '스토어',
    anchor: 'store',
    pathname: 'store',
  },
];

export const LEFTMENU = [
  {
    label: '로그인',
    anchor: 'login',
    login: true,
    pathname: '/login',
  },
  {
    label: '회원가입',
    anchor: 'join',
    login: true,
    pathname: '/join',
  },
  {
    label: '편성표 / 큐톤',
    anchor: 'schedule',
    login: false,
    pathname: '/schedule?tab=0',
  },
  {
    label: '이용권',
    anchor: 'voucher',
    login: false,
    pathname: '/voucher',
  },
  {
    label: '고객센터',
    anchor: 'help',
    login: false,
    pathname: '/help?tab=0',
  },
];

export const MOBILEMENU = [
  {
    label: '실시간 TV',
    anchor: 'ON-AIR',
    down: [
      {
        label: '실시간 TV',
        pathname: '/onair',
      },
    ],
  },
  {
    label: '편성표',
    anchor: 'scheule',
    down: [
      {
        label: '편성표',
        pathname: '/schedule?tab=0',
      },
      {
        label: '큐톤',
        pathname: '/schedule?tab=1',
      },
    ],
  },
  {
    label: '프로그램',
    anchor: 'vod',
    down: [
      {
        label: '전체',
        pathname: '/vod?tab=0&page=1',
      },
      {
        label: '종합',
        pathname: '/vod?tab=1&page=1',
      },
      {
        label: '민물',
        pathname: '/vod?tab=2&page=1',
      },
      {
        label: '바다',
        pathname: '/vod?tab=3&page=1',
      },
      {
        label: '루어',
        pathname: '/vod?tab=4&page=1',
      },
      {
        label: '기타',
        pathname: '/vod?tab=5&page=1',
      },
      {
        label: '종영 프로그램',
        pathname: '/vod?tab=6&page=1',
      },
    ],
  },
  {
    label: '커뮤니티',
    anchor: 'community',
    down: [
      {
        label: '공지사항',
        pathname: '/community?tab=0',
      },
      {
        label: '문의하기',
        pathname: '/community?tab=1',
      },
      {
        label: '조황정보',
        pathname: '/community?tab=2',
      },
      {
        label: '낚시정보',
        pathname: '/community?tab=3',
      },
      {
        label: '낚시대회/행사',
        pathname: '/community?tab=4',
      },
      {
        label: '이벤트',
        pathname: '/community?tab=5',
      },
    ],
  },
  {
    label: '스토어',
    anchor: 'store',
    down: [
      {
        label: '스토어',
        pathname: 'store',
      },
    ],
  },
  {
    label: '마이페이지',
    anchor: 'my',
    down: [
      {
        label: '이용내역',
        pathname: '/my?tab=0',
      },
      {
        label: '즐겨찾기',
        pathname: '/my?tab=1',
      },
      {
        label: '시청기록',
        pathname: '/my?tab=2',
      },
      {
        label: '문의내역',
        pathname: '/my?tab=3',
      },
      {
        label: '쿠폰관리',
        pathname: '/my?tab=4',
      },
      {
        label: '정보수정',
        pathname: '/my?tab=5',
      },
    ],
  },
  {
    label: '이용권 구매',
    pathname: '/voucher',
  },
  {
    label: '그 외 서비스 메뉴',
    anchor: 'atc',
    down: [
      {
        label: '회사소개',
        down: [
          {
            label: '회사소개',
            pathname: '/company?tab=0',
          },
          {
            label: 'FTV 브랜드',
            pathname: '/company?tab=1',
          },
          {
            label: 'FTV 홍보영상',
            pathname: '/company?tab=2',
          },
          {
            label: '아나운서 / 제작위원',
            pathname: '/company?tab=3',
          },
          {
            label: 'FTV 통신원',
            pathname: '/company?tab=4',
          },
        ],
      },
      {
        label: '사업제안',
        down: [
          {
            label: '광고 및 제휴',
            pathname: '/business',
          },
        ],
      },
      {
        label: '이용약관',
        LineChange: true,
        pathname: '/user-terms',
      },
      {
        label: '개인정보취급방침',
        pathname: '/personal',
      },
      {
        label: '고객센터',
        down: [
          {
            label: '공지사항',
            pathname: '/help?tab=0',
          },
          {
            label: '문의하기',
            pathname: '/help?tab=1',
          },
          {
            label: '자주 묻는 질문',
            pathname: '/help?tab=2',
          },
          {
            label: '방송채널안내',
            pathname: '/help?tab=3',
          },
          {
            label: '이벤트',
            pathname: '/help?tab=4',
          },
          {
            label: '자료실',
            pathname: '/help?tab=5',
          },
        ],
      },
    ],
  },
];

export const FOOTERMENU = [
  {
    label: '회사소개',
    anchor: 'companyInfo',
    pathname: '/company?tab=0',
  },
  {
    label: '사업제안',
    anchor: 'business',
    pathname: '/business',
  },
  {
    label: '이용약관',
    anchor: 'userTerms',
    pathname: '/user-terms',
  },
  {
    label: '개인정보취급방침',
    anchor: 'personal',
    pathname: '/personal',
  },
  {
    label: '고객센터',
    anchor: 'help',
    pathname: '/help?tab=0',
  },
  {
    label: '사이트맵',
    anchor: 'site-map',
    pathname: '/site-map',
  },
];

export const FOOTER_MOBILEMENU = [
  {
    label: '홈',
    anchor: 'home',
    pathname: '/home',
    icon: 'home',
  },
  {
    label: '실시간 TV',
    anchor: 'onair',
    pathname: '/onair',
    icon: 'onair',
  },
  {
    label: '검색',
    anchor: 'search',
    pathname: '/search',
    icon: 'search',
  },
  {
    label: '편성표 / 큐톤',
    anchor: 'schedule',
    pathname: '/schedule',
    icon: 'schedule',
  },
  {
    label: '마이',
    anchor: 'my',
    pathname: '/my',
    icon: 'my',
  },
];
