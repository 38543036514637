import React, { useEffect, useState, useRef, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { HeaderSearch } from '../styles/commonStyles';
import { IoIosSearch } from 'react-icons/io';
import { SearchAutoComplete } from '../pages/search/SearchAutoComplete';

import { connect } from 'react-redux';

const HeaderSearchBar = ({ programData }) => {
  const history = useHistory();

  const searchRef = useRef();
  const [value, setValue] = useState('');
  const [showAction, setShowAction] = useState(false);

  const [autoComplete, setAutoComplete] = useState([]);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);
  const [autoCompleteSelect, setAutoCompleteSelect] = useState(0);

  useEffect(() => {
    window.addEventListener('click', currentTargetClick);

    return () => window.addEventListener('click', currentTargetClick);
  }, []);

  const currentTargetClick = ({ target }) => {
    if (searchRef.current) {
      if (!searchRef.current.contains(target)) {
        setAutoCompleteOpen(false);
      }
    }
  };

  const selectTargetBox = useCallback(
    e => {
      let KEY_CODE = e?.keyCode;
      let UP = 38;
      let DOWN = 40;
      let ENTER = 13;
      let ESC = 27;

      if (KEY_CODE === DOWN || KEY_CODE === UP) {
        e.preventDefault();
        let cnt = autoCompleteSelect;

        if (KEY_CODE === DOWN) {
          if (cnt >= autoComplete?.length - 1) {
            cnt = 0;
          } else {
            cnt++;
          }
        }
        if (KEY_CODE === UP) {
          if (cnt > 0) {
            cnt--;
          } else {
            cnt = autoComplete?.length - 1;
          }
        }

        setAutoCompleteSelect(cnt);
      } else if (KEY_CODE === ENTER) {
        if (!autoComplete[autoCompleteSelect]) return;
        setValue('');
        goSearchPage(autoComplete[autoCompleteSelect]);
      } else if (KEY_CODE === ESC) {
        setAutoCompleteOpen(false);
      }
    },
    [autoComplete, autoCompleteSelect],
  );

  const handleChange = e => {
    let value = e.target.value;

    setValue(value);
    setAutoCompleteSelect(0);
    SearchAutoComplete(value, programData?.title).then(rt => {
      if (rt.length > 0) setAutoCompleteOpen(true);
      else setAutoCompleteOpen(false);
      setAutoComplete(rt);
    });
  };

  const handleAutoComplete = title => {
    if (!title) return;

    setValue('');
    goSearchPage(title);
  };

  const goSearchPage = title => {
    history.push(process.env.PUBLIC_URL + `/search?value=${title}&tab=0`);
  };

  return (
    <HeaderSearch ref={searchRef} showAction={showAction}>
      <div className='headerSearch_Input'>
        <input
          id='header_Search_input'
          type='text'
          placeholder='검색어'
          value={value}
          onChange={handleChange}
          onKeyDown={selectTargetBox}
          onFocus={() => setShowAction(true)}
          onBlur={() => setShowAction(false)}
          autoComplete='false'
        />
        <IoIosSearch onClick={() => handleAutoComplete(autoComplete[autoCompleteSelect])} />
      </div>
      {value && autoCompleteOpen && autoComplete.length > 0 && (
        <ul className='headerSearch_AutoComplete'>
          {autoComplete.map((title, idx) => (
            <li
              key={`search_autocomplte_${title}_${idx}_`}
              className={autoCompleteSelect === idx ? 'autoComplete_select' : ''}
              onClick={() => handleAutoComplete(title)}
            >
              <div className='autoComplete_Icon'>
                <IoIosSearch />
              </div>
              <p>{title}</p>
            </li>
          ))}
        </ul>
      )}
    </HeaderSearch>
  );
};

const mapStateToProps = state => {
  return {
    programData: state.programData,
  };
};

export default connect(mapStateToProps)(HeaderSearchBar);
