import { FETCH_STORAGE_SUCCESS } from '../actions/storageActions';

const initState = {};

const storageReducer = (state = initState, action) => {
  if (action.type === FETCH_STORAGE_SUCCESS) {
    return {
      ...state,
      storageData: action.payload,
    };
  }

  return state;
};

export default storageReducer;
