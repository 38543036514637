export const SearchAutoComplete = (text, list) => {
  let autoArr = [];
  return new Promise(resolve => {
    autoArr.push(text);

    if (text) {
      for (let li of list) {
        const regex = / /gi;
        const title = li.replace(regex, '');
        const _text = text.replace(regex, '');

        if (title.includes(_text)) autoArr.push(li);
        if (autoArr.length > 6) break;
      }
    }

    resolve(autoArr);
  });
};
