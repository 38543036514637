import moment from 'moment';
import UploadToS3 from '../components/FileUpload/UploadToS3';
import { UpdateData } from './api_index';

import Amplify, { Auth, Storage } from 'aws-amplify';
import awsconfig from '../aws-exports';

Amplify.configure(awsconfig);

export const API_URL = 'https://api.eftv.co.kr';
export const STORE_URL = 'https://smartstore.naver.com/eftv';

export const APP_PREFIX = 'ftv';
export const VALIDATECODE = 'diCode';
export const USR_ROLE = 'USR';
export const SNS_LOGIN_CODE = 'Eftv!!korea@2022';

export const ENC_KEY = `${APP_PREFIX}_ENCUSERID`;
export const POPUP_NAME = `${APP_PREFIX}_popup`;

export const GOOGLE_MAP_KEY = 'AIzaSyA3_7KePI10yo8EOIAAu9D093u4dAT1sQo';
export const GOOGLE_MAP_API = 'https://maps.googleapis.com/maps/api';

export const PROJECT_IMG = `${process.env.PUBLIC_URL}/assets/Ftv`;
export const PROJECT_ICON_IMG = `${process.env.PUBLIC_URL}/assets/Ftv/Icon`;

export const ADMIN = 'GR0105';
export const OUTSOURCING_PD = 'GR0102';
export const INSOURCING_PD = 'GR0103';

// 로그인 쿠키 옵션
export const COOKIE_OPTION = {
  path: '/',

  // secure: true,
  // httpOnly: true, - 도메인 .com 이여야만 사용가능..
};

// PLAY LOG UPDATE TIME
export const LOG_UPDATE_TIME = 45000;

//  이미지 없을시
export const handleImgError = (e, type) => {
  if (type === 'main') e.target.src = `${PROJECT_IMG}/no_main_Img.png`;
  else if (type === 'icon') e.target.src = `${PROJECT_IMG}/no_icon_Img.png`;
  else e.target.src = `${PROJECT_IMG}/noImg.png`;
};

export const PAYLIST = [
  {
    id: 'creditcard',
    name: '신용카드',
    isShow: true,
  },
  {
    id: 'mobile',
    name: '휴대폰 결제',
    isShow: true,
  },
  {
    id: 'naverpay',
    name: '네이버페이(간편결제)',
    isShow: true,
  },
  {
    id: 'kakaopay',
    name: '카카오페이(간편결제)',
    isShow: true,
  },
];

// 편성표 데이터 범위
// 현재 30일.
let DATERANGE = 30;
export const maxDate = moment().add(DATERANGE, 'days');
export const minDate = moment().subtract(DATERANGE, 'days');

// Mobile Check
export const isMobile = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};

export const bodyHiddenToggle = swich => {
  let body = document.querySelector('body');

  if (swich) {
    body.classList.add('hidden');
  } else {
    body.classList.remove('hidden');
  }
};

// 날짜 변환
export const setCreatedAt = createdAt => {
  if (!(createdAt > 0)) return '-';

  const dt = new Date(Number(createdAt));
  let addDt =
    dt.getFullYear() +
    '.' +
    ('00' + (dt.getMonth() + 1).toString()).slice(-2) +
    '.' +
    ('00' + dt.getDate().toString()).slice(-2) +
    ' ' +
    ('00' + dt.getHours().toString()).slice(-2) +
    ':' +
    ('00' + dt.getMinutes().toString()).slice(-2);

  return addDt.slice(0, 10);
};

// 시간 형식 변경
export const timeFormat = seconds => {
  if (isNaN(seconds)) {
    return `0:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, '0');
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, '0')}:${ss}`;
  }
  return `${mm}:${ss}`;
};

// 파일 다운로드
export const fileDownload = async files => {
  await Storage.get(files.key, { customPrefix: { public: '' } }).then(res => {
    const linkEle = document.createElement('a');
    linkEle.href = res;

    linkEle.setAttribute('href', res);
    linkEle.download = `${res}`;
    linkEle.click();
  });
};

export const textSilce = (title, silce) => {
  if (!title) return;

  return title.length > silce ? `${title.slice(0, silce)}...` : title;
};

export const addComma = num => {
  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  let _num = num + '';
  return _num.toString().replace(regexp, ',');
};

export const removeStorage = () => {
  for (let [k, v] of Object.entries(localStorage)) {
    if (k !== 'theme') {
      localStorage.removeItem(k);
    }
  }
};

export const changeFile = async (e, maxCount) => {
  const files = e.target.files;

  if (maxCount && files.length > maxCount) {
    alert(`max file count : ${maxCount}`);
    return;
  }

  let arr = [];
  let buffs = [];
  let reslut = {};

  for (let i = 0; i < files.length; i++) {
    const temp = files[i];

    await readFile(temp, -1)
      .then(file => {
        try {
          temp['path'] = file.target.result;

          if (file.total < 209715200) {
            var video = new Blob([new Uint8Array(file.target.result)]);
            let buff = URL.createObjectURL(video);
            buffs.push(buff);
          } else {
            buffs.push(null);
          }
        } catch (e) {
          if (buffs.length <= i) {
            buffs.push(null);
          } else {
            buffs[i] = null;
          }
        }
      })
      .catch(console.log);

    arr.push(temp);
  }

  reslut = { arr: arr, buffs: buffs };
  return reslut;
};

export const readFile = (file, limit) => {
  // if (limit < 0) limit = 5;
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    file.type.split('/')[0] === 'image' ? fileReader.readAsDataURL(file) : fileReader.readAsArrayBuffer(file);

    fileReader.onload = _read => {
      if (limit > 0 && _read.total > 1000000 * limit) {
        reject(`File too large : limit ${limit}MB`);
      } else {
        resolve(_read);
      }
    };
  });
};

export const fileUpload = async (uploadFile, fileId, folder, apiUrl, callback, pgId) => {
  let param = { id: fileId };
  if (apiUrl === 'opinion') param['programId'] = pgId;
  const upKey = 'files';
  let files = [];

  let upFile = uploadFile;
  for await (let file of upFile) {
    let result = await UploadToS3(file, folder, fileId, callback);

    files.push({
      key: result.key,
      name: file.name,
      size: file.size,
      type: file.type,
      url: result.path,
    });
  }

  param[upKey] = files;

  return await UpdateData(apiUrl, param);
};

export const searchDataRender = (e, _list) => {
  let __LIST__ = [];

  _list.map(s => {
    if (e.tab === 'createdAt') {
      let __createdAt = '';
      setCreatedAt(s.createdAt)
        .split('.')
        .map(c => {
          __createdAt += c;
        });

      if (__createdAt.includes(e.value)) __LIST__.push(s);
    } else {
      if (s[e.tab].includes(e.value)) __LIST__.push(s);
    }
  });

  return __LIST__;
};

export const renderProcessingStatus = status => {
  let result;

  if (!status) result = '답변대기';
  if (status) result = '답변완료';

  return result;
};

// 스토리지에 저장한 데이터 값 가지고 오는 소스.
export const storageData = JSON.parse(sessionStorage.getItem('persist:ftv'))?.root;

export const getStorageCategory = () => {
  let rtData;
  let json = storageData ? JSON.parse(storageData)?.storageData : {};

  if (json?.code) rtData = json?.code;

  return rtData ? rtData : [];
};

export const getStorageInfoData = id => {
  let rtData;
  let json = storageData ? JSON.parse(storageData)?.storageData : {};

  if (json?.info) {
    rtData = json?.info?.filter(i => {
      if (i.id === id) return i;
    });
  }

  return rtData ? rtData[0] : [];
};

export const getStorageNoticeData = () => {
  let rtData;
  let json = storageData ? JSON.parse(storageData)?.storageData : {};

  if (json?.noti) {
    rtData = json?.noti;
  }

  return rtData ? rtData : [];
};

export let checkNumber = /^(\(?\+?[0-9]*\)?)?[0-9_\- \(\)]*$/;
