import { FETCH_USERS_SUCCESS } from '../actions/userActions';

const initState = {
  user: '',
  userAction: '',
};

const userReducer = (state = initState, action) => {
  if (action.type === FETCH_USERS_SUCCESS) {
    return {
      ...state,
      user: action.user,
      userAction: action.userAction,
    };
  }

  return state;
};

export default userReducer;
