import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';

import UserReducer from './userReducer';
import storageReducer from './storageReducer';
import BannerReducer from './bannerReducer';
import ProgramReducer from './programReducer';
import EpisodeReducer from './episodeReducer';

const persistConfig = {
  key: 'ftv',
  storage: storageSession,
  whitelist: ['root'],
};

const rootReducer = combineReducers({
  userData: UserReducer,
  episodeData: EpisodeReducer,
  programData: ProgramReducer,
  bannerData: BannerReducer,

  // Storage Save
  root: storageReducer,
});

export default persistReducer(persistConfig, rootReducer);
