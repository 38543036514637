import React from 'react';
import { List } from '../../styles/commonStyles';
import { setCreatedAt } from '../../constants/index';

const ListComponent = ({ id, title, contents, date, width, link, onClick, titleBold, fontSize, padding, active, sort, style }) => {
  return (
    <List
      id={id}
      width={width}
      active={active}
      onClick={onClick}
      link={link}
      titleBold={titleBold}
      fontSize={fontSize}
      padding={padding}
      sort={sort}
      style={style}
    >
      <h1>{title}</h1>
      {contents && <h3>{contents}</h3>}
      {date && <p>{setCreatedAt(date)}</p>}
    </List>
  );
};

export default ListComponent;
