import bannerService from '../service/bannerService';

export const FETCH_BANNER_SUCCESS = 'FETCH_BANNER_SUCCESS';

const bannerActionsSuccess = banner => ({
  type: FETCH_BANNER_SUCCESS,
  banner: banner,
});

export const bannerActions = () => {
  return async dispatch => {
    let res = await bannerService.getBanner();
    let bannerList = res?.value ? res?.value : [];

    dispatch(bannerActionsSuccess(bannerList.sort((a, b) => (a.updatedAt > b.updatedAt ? 1 : -1))));
  };
};
