import React, { useState, useEffect, useRef, useContext } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import { SearchMobileValueBar } from '../styles/SearchStyles';
import { SearchAutoComplete } from '../pages/search/SearchAutoComplete';

import { IoIosSearch } from 'react-icons/io';
import { LayoutContext } from '../context/layoutContext';
import { bodyHiddenToggle } from '../constants/index';

import AddCircleIcon from '@material-ui/icons/AddCircle';

import { connect } from 'react-redux';

const HeaderMobileSearchBar = ({ programData }) => {
  const query = queryString.parse(window.location.search);
  const { matchese } = useContext(LayoutContext);
  const history = useHistory();

  const searchRef = useRef();
  const searchInputRef = useRef();

  const [searchValue, setSearchValue] = useState('');

  const [autoComplete, setAutoComplete] = useState([]);
  const [autoCompleteOpen, setAutoCompleteOpen] = useState(false);

  useEffect(() => {
    searchInputRef.current.focus();
  }, []);

  useEffect(() => {
    if (!matchese) {
      setSearchValue(query?.value ? query?.value : '');
    }
  }, [query?.value]);

  useEffect(() => {
    window.addEventListener('click', currentTargetClick);

    return () => window.addEventListener('click', currentTargetClick);
  }, []);

  useEffect(() => {
    if (searchValue && autoCompleteOpen) {
      bodyHiddenToggle(true);
    } else {
      bodyHiddenToggle(false);
    }
  }, [searchValue && autoCompleteOpen]);

  useEffect(() => {
    if (autoComplete.length > 0) setAutoCompleteOpen(true);
    else setAutoCompleteOpen(false);
  }, [autoComplete]);

  const currentTargetClick = ({ target }) => {
    if (searchRef.current) {
      if (!searchRef.current.contains(target)) {
        setAutoCompleteOpen(false);
      }
    }
  };

  const handleChangeInput = e => {
    let value = e?.target?.value;
    setSearchValue(value);

    SearchAutoComplete(value, programData?.title).then(rt => {
      setAutoComplete(rt);
    });
  };

  const handleAutoComplete = title => {
    goSearchPage(title);
  };

  const clearInput = () => {
    setSearchValue('');
    searchInputRef.current.focus();
  };

  const selectTargetBox = e => {
    if (e.keyCode === 13) {
      if (!searchValue) return;
      goSearchPage(searchValue);
    }
  };

  const goSearchPage = title => {
    setSearchValue(title);
    setAutoCompleteOpen(false);
    history.push(process.env.PUBLIC_URL + `/search?value=${title}&tab=0`);
  };

  const openAutoComplete = () => {
    setAutoCompleteOpen(true);
  };

  return (
    <SearchMobileValueBar open={searchValue && autoCompleteOpen}>
      <nav className='headerSearch_Input_m' ref={searchRef}>
        <IoIosSearch className='input_Icon' />
        <input
          id='mobile_Search_Input'
          ref={searchInputRef}
          value={searchValue}
          onChange={handleChangeInput}
          onKeyDown={selectTargetBox}
          onFocus={openAutoComplete}
        />
        <AddCircleIcon className='clearInput_Icon' onClick={clearInput} />
      </nav>
      {searchValue && autoCompleteOpen && (
        <>
          <ul className='headerSearch_AutoComplete_m'>
            {autoComplete.map((title, idx) => (
              <li key={`search_autocomplte_${title}_${idx}_`} onClick={() => handleAutoComplete(title)}>
                <div className='autoComplete_Icon'>
                  <IoIosSearch />
                </div>
                <p>{title}</p>
              </li>
            ))}
          </ul>
          <div className='headerSearch_BgBlock_m' />
        </>
      )}
    </SearchMobileValueBar>
  );
};

const mapStateToProps = state => {
  return {
    programData: state.programData,
  };
};

export default connect(mapStateToProps)(HeaderMobileSearchBar);
