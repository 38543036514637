import axiosInstance from '../../constants/AxiosInstance';
import { API_URL } from '../../constants';

class codeService {
  getCode() {
    return axiosInstance.get(`${API_URL}/code`, { headers: { Pragma: 'no-cache' } });
  }
}

export default new codeService();
