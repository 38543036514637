import InfoService from '../service/infoService';
import NoticeService from '../service/noticeService';
import CodeService from '../service/codeService';

export const FETCH_STORAGE_SUCCESS = 'FETCH_STORAGE_SUCCESS';

const storageActionsSuccess = data => ({
  type: FETCH_STORAGE_SUCCESS,
  payload: data,
});

export const storageActions = () => {
  return dispatch => {
    Promise.all([InfoService.getInfo(), CodeService.getCode(), NoticeService.getData()]).then(api => {
      let info;
      let code;
      let noti;

      api.map((res, idx) => {
        let result = res?.data?.value;
        if (idx === 0) info = result;
        if (idx === 1) code = result;
        if (idx === 2) noti = result;
      });

      let storageData = {
        info: info,
        code: code,
        noti: noti,
      };

      dispatch(storageActionsSuccess(storageData));
    });
  };
};
