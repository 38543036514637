import CryptoJS from 'crypto-js';

export const encrypt = (data, key) => {
  return CryptoJS.AES.encrypt(data, key).toString();
};

export const decrypt = (text, key) => {
  try {
    const bytes = CryptoJS.AES.decrypt(text, key);
    return bytes.toString(CryptoJS.enc.Utf8);
  } catch (err) {
    // console.log(err);
    return;
  }
};
