import styled, { css, keyframes, createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

// 모바일 변형되는 사이즈 (아이패드, 노트)
export const MOBILE_SIZE = '980px';

// layOut
export const smLayout = `@media screen and (max-width: ${MOBILE_SIZE})`;
export const mdLayout = `@media screen and (min-width: 790px) and (max-width: ${MOBILE_SIZE})`;
export const xlLayout = `@media screen and (min-width: ${MOBILE_SIZE}) and (max-width: 1365px)`;
export const uxLayout = `@media screen and (min-width: 1921px) and (max-width: 3820px)`;

// category Color
export const synthesis = `#DB5137`;
export const lure = `#885BF8`;
export const sea = `#4478FE`;
export const fresh = `#DCAC2B`;

// -------------------------------

// Theme Styled
export const GlobalStyle = createGlobalStyle`
  ${normalize}
  
  body {
    background: ${props => props.theme.background};
    overscroll-behavior: none;
    overflow-x: hidden;
  }
`;
//  -------------

export const NavBar = styled.div`
  background-color: ${props => props.theme.secondaryBackground};

  padding: 25px 15%;

  ${uxLayout} {
    padding: 20px 20%;
  }

  ${xlLayout} {
    padding: 20px 10%;
  }

  ${smLayout} {
    padding: 20px 5%;
  }
`;

export const MainVodListBox = styled.article`
  margin: 0 10% 2em;
  padding: 0 5%;

  ${smLayout} {
    margin: 0;
    padding: 1em 5%;
    min-height: auto;
  }

  ${xlLayout} {
    padding: 0 10%;
  }

  ${uxLayout} {
    margin: 0 20% 2em;
  }
`;

export const RecVodListBox = styled.article`
  margin: 2em 10%;
  padding: 0 5%;

  ${smLayout} {
    margin: 0;
    padding: 1em 5%;
    min-height: auto;
  }
  ${uxLayout} {
    margin: 2em 15%;
  }
`;

export const Body = styled.article`
  padding: 0 15%;

  ${smLayout} {
    padding: 0 5%;
  }

  ${xlLayout} {
    padding: 0 10%;
  }

  ${uxLayout} {
    padding: 0 20%;
  }

  ${props =>
    props.Ptb &&
    css`
      padding-top: 2em !important;
      padding-bottom: 2em !important;

      ${smLayout} {
        padding-top: 1em !important;
        padding-bottom: 1em !important;
      }
    `}

  ${props =>
    props.Pt &&
    css`
      padding-top: 2em !important;

      ${smLayout} {
        padding-top: 1em !important;
      }
    `}

  ${props =>
    props.Pb &&
    css`
      padding-bottom: 2em !important;

      ${smLayout} {
        padding-bottom: 1em !important;
      }
    `}

  ${props =>
    props.Pn &&
    css`
      padding-left: 0 !important;
      padding-right: 0 !important;
    `}
`;

export const MobileBody = styled.article`
  ${smLayout} {
    padding: 0 5%;
  }
`;

export const LoginPageBody = styled.div`
  padding: 8em 37%;

  ${xlLayout} {
    padding: 1em 35%;
  }

  ${smLayout} {
    padding: 1em 7%;
  }

  ${uxLayout} {
    width: 450px;
    margin: 8em auto;
    padding: 0;
  }
`;

export const JoinPageBody = styled.div`
  padding: 5em 20%;
  min-height: 80vh;
  background-color: ${props => (props.theme.theme === 'dark' ? '#101112' : '#fafafa')};

  & .joinBox {
    padding: 4em 5% 1em;
    background-color: ${props => props.theme.background};
  }

  & article {
    margin: 10px 0;
    padding: 16px;
    font-size: 14px;
    max-height: 185px;
    color: ${props => props.theme.text};
    border: 1px solid ${props => props.theme.line};
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
  }

  ${xlLayout} {
    padding: 1em 10%;
  }

  ${smLayout} {
    background-color: ${props => props.theme.background};
    padding: 1em 5%;

    & .joinBox {
      padding: 0em 0% 2em;
      background-color: transparent;
    }
  }
`;

export const OnAirMainBody = styled.div`
  padding: 1em 16% 0em;

  ${uxLayout} {
    padding: 2em 21% 1em;
  }

  ${xlLayout} {
    padding: 0 11%;
  }

  ${smLayout} {
    padding: 0%;
    & > div {
      padding: 0 5% !important;
    }
  }
`;

export const KaKaoBtn = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  margin: 10px 0;

  cursor: pointer;

  ${props =>
    props.theme.theme === 'dark'
      ? css`
          background-color: #fff200;
        `
      : css`
          background-color: #ffffff;
        `};

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
    border: 1px solid ${props => props.theme.line};
    & img {
      width: 40%;
      height: auto;
    }
  }
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: 100%;
    font-weight: 500;
    color: #232323;
    border: 1px solid ${props => props.theme.line};
  }

  ${xlLayout} {
    & img {
      width: 30% !important;
    }
  }

  ${smLayout} {
    height: 50px;
  }
`;

export const NaverBtn = styled.div`
  width: 100%;
  height: 55px;
  display: flex;
  margin: 10px 0;

  cursor: pointer;

  ${props =>
    props.theme.theme === 'dark'
      ? css`
          background-color: #47b748;
        `
      : css`
          background-color: #ffffff;
        `};

  & span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 15%;
    height: 100%;
    border: 1px solid ${props => props.theme.line};
    & img {
      width: 40%;
      height: auto;
    }
  }
  & div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 85%;
    height: 100%;
    font-weight: 500;
    border: 1px solid ${props => props.theme.line};

    ${props =>
      props.theme.theme === 'dark'
        ? css`
            color: #ffffff;
          `
        : css`
            color: #232323;
          `};
  }

  ${xlLayout} {
    & img {
      width: 30% !important;
    }
  }

  ${smLayout} {
    height: 50px;
  }
`;

// animation
export const dot = keyframes`
  from {
    opacity:0;
    transform: scale(0);
  }
  to {
    opacity:1;
    transform: scale(1);
  }
`;
