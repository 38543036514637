import { FETCH_EPISODE_SUCCESS, FETCH_EPISODE_LEAVE } from '../actions/episodeActions';

const initState = {
  episode: [],
  step: 0,
};

const episodeReducer = (state = initState, action) => {
  if (action.type === FETCH_EPISODE_SUCCESS) {
    return {
      ...state,
      episode: action.payload,
      step: action.step,
    };
  } else if (action.type === FETCH_EPISODE_LEAVE) {
    return {
      ...state,
      episode: action.payload,
      step: action.step,
    };
  }

  return state;
};

export default episodeReducer;
