import EpisodeService from '../service/episodeService';
import queryString from 'query-string';
import moment from 'moment';

export const FETCH_EPISODE_SUCCESS = 'FETCH_EPISODE_SUCCESS';
export const FETCH_EPISODE_LEAVE = 'FETCH_EPISODE_LEAVE';

const episodeActionsSuccess = (type, episode, step) => ({
  type: type,
  payload: episode,
  step: step,
});

export const episodeActions = () => {
  const query = queryString.parse(window?.location?.search);
  let id = query?.id;

  return async dispatch => {
    const nowData = Number(moment().format('YYYYMMDDHHmm'));
    let res = await EpisodeService.getEpisode(id);
    let result = [];
    let check = [];

    res?.value.sort((a, b) => {
      const sortA = a?.broadcastDate ? a?.broadcastDate : a?.createdAt;
      const sortB = b?.broadcastDate ? b?.broadcastDate : b?.createdAt;

      return Number(sortA) < Number(sortB) ? 1 : -1;
    });

    res?.value.map(data => {
      const etStartDate = Number(moment(data?.effectiveStartDate).format('YYYYMMDDHHmm'));
      const bcStartDate = Number(moment(data?.broadcastDate).format('YYYYMMDDHHmm'));

      if (etStartDate) {
        if (etStartDate < nowData) result.push(data);
        else check.push(data);
      } else if (bcStartDate) {
        if (bcStartDate < nowData) result.push(data);
        else check.push(data);
      }
    });

    dispatch(episodeActionsSuccess(FETCH_EPISODE_SUCCESS, result.length ? result : [], 1));
  };
};

export const episodeLeave = () => {
  return async dispatch => {
    dispatch(episodeActionsSuccess(FETCH_EPISODE_LEAVE, [], 0));
  };
};
