import AWS from 'aws-sdk';
import awsconfig from '../../aws-exports';

const S3_BUCKET = 'file.eftv.site';
const REGION = 'ap-northeast-2';

AWS.config.update({
  accessKeyId: awsconfig.aws_access_key,
  secretAccessKey: awsconfig.aws_secret_key,
});

const myBucket = new AWS.S3({ params: { Bucket: S3_BUCKET }, region: REGION });

const UploadToS3 = (file, folder, id, callback, keyName) => {
  return new Promise((resolve, reject) => {
    const orgFileName = file.name;
    const ext = file.name.substr(file.name.lastIndexOf('.') + 1);
    let _key = keyName ? folder + '/' + id + '_' + keyName + '.' + ext : folder + '/' + id + '_' + file.name;
    let params = { ACL: 'public-read', Body: file, Bucket: S3_BUCKET, Key: _key };

    myBucket
      .putObject(params)
      .on('httpUploadProgress', evt => {
        // setProgress(Math.round((evt.loaded / evt.total) * 100))
        if (callback) callback(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err, data) => {
        if (err) console.log(err);
        else {
          resolve({
            key: _key,
            name: orgFileName,
            path: `https://s3.${REGION}.amazonaws.com/${S3_BUCKET}/${_key}`,
          });
        }
      });
  });
};

export default UploadToS3;
