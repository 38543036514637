import UserService from '../service/userService';
import { decrypt } from '../../helpers/crypto';
import { getCookie } from '../../helpers/cookies';
import { APP_PREFIX, ENC_KEY } from '../../constants/index';

export const FETCH_USERS_SUCCESS = 'FETCH_USERS_INFO_SUCCESS';

const userActionsSuccess = (user, userAction) => ({
  type: FETCH_USERS_SUCCESS,
  user: user,
  userAction: userAction,
});

export const userActions = () => {
  let cookie = getCookie(`${APP_PREFIX}_user`);
  let id;

  if (cookie) id = decrypt(cookie, ENC_KEY);

  return async dispatch => {
    Promise.all([UserService.getUserInfo(id), UserService.getUserActionInfo(id)]).then(async api => {
      let user;
      let userAction;

      for await (let res of api) {
        const data = res?.data?.value ? res?.data?.value : '';

        if (data?.id) {
          user = data;
        } else {
          userAction = data;
        }
      }

      // console.log(user);

      dispatch(userActionsSuccess(user, userAction));
    });
  };
};
