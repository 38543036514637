import styled, { css } from 'styled-components';
import { smLayout } from './homeStyles';

export const Flex = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props =>
    props.flex &&
    css`
      flex: ${props.flex};
    `};
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};
  ${props =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `}
  ${props =>
    props.flexWrap &&
    css`
      flex-wrap: ${props.flexWrap};
    `}
  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `};
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `};
`;

export const Grid = styled.div`
  ${props =>
    props.container &&
    css`
      display: flex;
    `}
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
  ${props =>
    props.alignItems &&
    css`
      align-items: ${props.alignItems};
    `};
  ${props =>
    props.flexWrap &&
    css`
      flex-wrap: ${props.flexWrap};
    `};
  ${props =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `};

  ${props =>
    props.xs &&
    css`
      flex-grow: 0;
      max-width: ${(100 / 12) * props.xs}%;
      flex-basis: ${(100 / 12) * props.xs}%;
    `}
  ${props =>
    props.sm &&
    css`
      ${smLayout} {
        flex-grow: 0;
        max-width: ${(100 / 12) * props.sm}%;
        flex-basis: ${(100 / 12) * props.sm}%;
      }
    `}
`;

export const Div = styled.div`
  font-size: 1em;
  color: ${props => props.theme.text};
  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `}
  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `}
`;

export const TextBox = styled.span`
  display: flex;
  align-items: center;
  color: ${props => props.theme.text};

  & b {
    font-weight: bold;
    margin: 0 5px;
  }

  & .red {
    color: #ff0000;
  }

  & .blue {
    color: ${props => props.theme.button};
  }

  & .sub {
    color: ${props => props.theme.subText};
  }

  ${props =>
    props.fontSize &&
    css`
      font-size: ${props.fontSize};
    `};

  ${props =>
    props.color &&
    css`
      color: ${props.color};
    `};

  ${props =>
    props.margin &&
    css`
      margin: ${props.margin};
    `};

  ${props =>
    props.padding &&
    css`
      padding: ${props.padding};
    `};

  ${props =>
    props.cursor &&
    css`
      cursor: ${props.cursor};
    `};

  ${props =>
    props.textAlign &&
    css`
      text-align: ${props.textAlign};
    `};
  ${props =>
    props.justifyContent &&
    css`
      justify-content: ${props.justifyContent};
    `};
  ${props =>
    props.flexDirection &&
    css`
      flex-direction: ${props.flexDirection};
    `};
  ${props =>
    props.width &&
    css`
      width: ${props.width};
    `};
  ${props =>
    props.wordBreak &&
    css`
      word-break: ${props.wordBreak};
    `};
  ${props =>
    props.lineHeight &&
    css`
      line-height: ${props.lineHeight};
    `};
  ${props =>
    props.textDecoration &&
    css`
      text-decoration: ${props.textDecoration};
    `};

  ${props =>
    props.active &&
    css`
      color: ${props => props.theme.button};
    `};
  ${props =>
    props.point &&
    css`
      &:after {
        content: '*';
        color: red;
        margin-left: 4px;
      }
    `};
`;
