import styled, { css } from 'styled-components';
import { smLayout } from './homeStyles';

export const SearchValueBar = styled.header`
  width: 100%;
  padding: 35px 15%;
  color: ${props => props.theme.text};
  background-color: ${props => props.theme.secondaryBackground};

  & > article {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 21px;

    & > b {
      color: ${props => props.theme.button};
      font-weight: bold;
      margin: 0 9px;
    }
  }
`;

export const SearchMobileValueBar = styled.div`
  width: 100%;
  position: relative;

  & .headerSearch_Input_m {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.secondaryBackground};
    margin: 5px 0;
    padding: 0px 5%;

    & > input {
      width: 80%;
      height: 52px;
      padding: 0 6px;
      margin: 0 5px;
      border: none;
      outline: none;
      background-color: ${props => props.theme.secondaryBackground};
      color: ${props => props.theme.text};
    }
    & .input_Icon {
      width: 24px;
      height: 24px;
      color: ${props => props.theme.text};
    }

    & .clearInput_Icon {
      width: 22px;
      height: 22px;
      transform: rotate(45deg);
      color: ${props => props.theme.subLine};
    }
  }

  & .headerSearch_AutoComplete_m {
    position: fixed;
    width: 100%;
    background: ${props => props.theme.background};
    display: block;
    right: 0;
    z-index: 8500;
    padding: 2% 5%;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    & li {
      color: ${props => props.theme.text};
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 7px 13px;

      & p {
        margin-left: 10px;
        flex: 1;
      }
    }
  }

  & .headerSearch_BgBlock_m {
    content: '';
    position: fixed;
    width: 100vw;
    height: 100vh;
    right: 0px;
    background-color: rgb(0 0 0/ 0.2);
  }

  & .autoComplete_Icon {
    color: #ffffff;
    width: 29px;
    height: 29px;
    border-radius: 50%;
    background-color: #4b535f;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SearchTab = styled.nav`
  width: 100%;
  padding: 0 15% 15px;
  border-bottom: 1px solid ${props => props.theme.line};

  ${smLayout} {
    padding: 0 5% 10px;
  }
`;

export const SearchValueBoxStyle = styled.article`
  width: 100%;
  margin: 2em 0;

  ${props =>
    props.hide &&
    css`
      display: none;
      visibility: hidden;
      opacity: 0;
    `}

  & .searchValueContainer {
    padding: 10px 1%;
    display: flex;
    flex-wrap: wrap;

    ${props =>
      props.flexDirection &&
      css`
        flex-direction: ${props.flexDirection};
      `}
  }

  ${smLayout} {
    margin: 1em 0;

    & .searchValueContainer {
      padding: 10px 1% 0;
    }

    & .searchMobileOption {
      margin-bottom: 30px !important;
    }
  }
`;

export const SearchDetailBoxStyle = styled.article`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;

  & > article {
    width: 100%;
    padding: 0;
  }
`;
