import axios from 'axios';
import { Auth } from 'aws-amplify';
import { API_URL, COOKIE_OPTION } from './index';
import { getCookie, setCookie } from '../helpers/cookies';
import awsconfig from '../aws-exports';
import Alert from '../helpers/Alert';
import { logOut } from '../components/Topbar';

Auth.configure(awsconfig);

// import Alert from '../helpers/Alert';

const JWT_KEY = 'ftv_jwt';
const TOKEN = getCookie(JWT_KEY);
const axiosInstance = axios.create({
  baseURL: API_URL,
  timeout: 1000 * 30,
  headers: {
    Pragma: 'no-cache',
    CacheControl: 'no-cache',
    Expires: '0',
    usertype: 'user',
    Authorization: TOKEN,
  },
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = TOKEN;
    config.headers.Authorization = token;
    config.headers.usertype = 'user';
    return config;
  },
  function (error) {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    // console.log(error);
    if (error.response) {
      // console.log(error.response.data);
      // console.log(error.response.status);
      // console.log(error.response.headers);
      // const orgRequest = error.config;
      if (error.response && error.response.status === 401) {
        // console.log('토큰 만료');
        tokenRefresh()
          .then(refreshRes => {
            if (refreshRes) {
              window.location.reload();
            }
          })
          .catch(err => {
            // console.log(err.message);
          });
      }
      return Promise.resolve(error.response.data);
    } else if (error.request) {
      // console.log(error.request);
    } else {
      // console.log('Error', error.message);
    }
    // console.log(error.config);

    return Promise.resolve(error); //reject(error)
  },
);

export const tokenRefresh = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();

    return new Promise(function (resolve, reject) {
      cognitoUser.refreshSession(currentSession.refreshToken, (err, session) => {
        if (session) {
          const { accessToken } = session;

          // removeCookie(JWT_KEY);
          setCookie(JWT_KEY, accessToken.jwtToken, COOKIE_OPTION);
          resolve(accessToken.jwtToken);
        }
      });
    });
  } catch (e) {
    if (e === 'The user is not authenticated') {
      Alert('', '계정정보가 만료되었습니다. <br /> 다시 로그인 후 이용가능합니다.', '', '', () => {
        logOut();
      });
    }
  }
};

export default axiosInstance;
