import axiosInstance from '../../constants/AxiosInstance';
import { API_URL } from '../../constants';

class noticeService {
  getData() {
    return axiosInstance.get(`${API_URL}/notice`, { headers: { Pragma: 'no-cache' } });
  }
}

export default new noticeService();
